import React from 'react';
import Export from "./export/Export";
import SearchUsersV3 from "./roles/v3/SearchUsersV3";
import SearchRolesV3 from "./roles/v3/SearchRolesV3";
import SearchRightsV3 from "./roles/v3/SearchRightsV3";
import SearchOrganisationsV3 from "./roles/v3/SearchOrganisationsV3";
import QrPaint from "./qrpaint/QrPaint";
import {User} from "../model/User";
import OrganisationHierarchies from "./roles/v3/organisation/OrganisationHierarchies";
import CompletenessIndex from "./completeness/index/CompletenessIndex";
import ForeignPatientReport from "./foreign/patient/report/ForeignPatientReport";

interface Props {
    refreshExpiredToken: () => void,
    securityToken: string,
    user: User
}

interface State {
    currentAction: string,
    actions: string[]
}

class Backoffice extends React.Component<Props, State> {
    state: State = {
        currentAction: "none",
        actions: [
            "Manage users v3",
            "Manage roles v3",
            "Manage rights v3",
            "Manage organisations v3",
            "Organisation hierarchies v3",
            "Export capio-hsf",
            "Export capio-blood",
            "Export lia 2",
            "Export airway",
            "Export crf-airway-er",
            "Qr Paint",
            "Completeness index",
            "Foreign patient report"
        ],
    }

    updateContent = (obj: any) => {
        this.setState({currentAction: obj});
    };

    render() {
        return (
            <div className={"row"}>
                <div className={"col-2"}>
                    {this.verticalMenu()}
                </div>
                <div className={"col-10 pt-5"}>
                    {this.current()}
                </div>
            </div>
        );
    }

    private current() {
        if (this.state.currentAction === "Manage users v3") {
            return <div>
                <SearchUsersV3/>
            </div>;
        }

        if (this.state.currentAction === "Manage roles v3") {
            return <div>
                <SearchRolesV3/>
            </div>;
        }

        if (this.state.currentAction === "Manage rights v3") {
            return <div>
                <SearchRightsV3/>
            </div>;
        }

        if (this.state.currentAction === "Manage organisations v3") {
            return <div>
                <SearchOrganisationsV3/>
            </div>;
        }

        if (this.state.currentAction === "Organisation hierarchies v3") {
            return <div>
                <OrganisationHierarchies/>
            </div>;
        }

        if (this.state.currentAction === "Export capio-hsf") {
            return <div>
                <Export action={'capio-hsf'}
                        version={3}
                />
            </div>;
        }

        if (this.state.currentAction === "Export capio-blood") {
            return <div>
                <Export action={'capio-blood'}
                        version={0}
                />
            </div>;
        }

        if (this.state.currentAction === "Export lia 2") {
            return <div>
                <Export action={'traindriver-lia-2'}
                        version={0}
                />
            </div>;
        }

        if (this.state.currentAction === "Export airway") {
            return <div>
                <Export action={'airway'}
                        version={2}
                />
            </div>;
        }

        if (this.state.currentAction === "Export crf-airway-er") {
            return <div>
                <Export action={'crf-airway-er'}
                        version={0}
                />
            </div>;
        }

        if (this.state.currentAction === "Qr Paint") {
            return <div>
                <QrPaint/>
            </div>;
        }

        if (this.state.currentAction === "Completeness index") {
            return <div>
                <CompletenessIndex/>
            </div>;
        }

        if (this.state.currentAction === "Foreign patient report") {
            return <div>
                <ForeignPatientReport/>
            </div>;
        }
    }

    private verticalMenu() {
        return <div>
            <div className={"vertical-nav text-left"}>
                <ul className={"nav flex-column"}>
                    {
                        this.state.actions.map(action => {
                                let active;
                                if (action === this.state.currentAction) {
                                    active = "active"
                                } else {
                                    active = "inactive"
                                }
                                return <li key={action}
                                           className={"nav-link"}>
                                    <button className={"nav-item " + active}
                                            onClick={() => this.updateContent(action)}>
                                        {action}
                                    </button>
                                </li>
                            }
                        )
                    }
                </ul>
            </div>
        </div>;
    }
}

export default Backoffice;
