import React from 'react';
import {ModalDisplayBinder} from "../KnarkbokModal";
import {KnarkbokService} from "../Knarkbokservice";
import {DiscardReason, Partials} from "../models/Models";
import "./Move.css";
import "./PartialAdminister.css"
import {BackendContext, BackendInterface} from "../../../infra/BackendContext";
import DeleteLogo from "../../../images/delete-512.png"
import DeleteLogoDisabled from "../../../images/delete-768-768-disabled.png";
import Unlock from "../../../images/unlock-512.png";
import UnlockLocked from "../../../images/unlock-512-locked.png";
import TranslationService from "../../../infra/TranslationService";
import UserSessionUtilService from "../../model/UserSessionUtilService";
import {Resolution} from "../../../infra/Constants";
import {Organisation} from "../../model/Organisation";
import {EventBackendService} from "../../register/v2/EventBackendService";

interface props {
    closeModal: () => void;
    book: ModalDisplayBinder;
    KBService: KnarkbokService;
    isDruglord: boolean;
    organisation: Organisation;
    partials?: Partials[]
    flipToAdminister?: () => void
}

interface InnerPartial {
    bottleLabel: string
    discardUsed: boolean
    weightBefore: string
    weightAfter: string
    amount: string
    inventory?: string
}

interface state {
    spinner: boolean;
    inputDiscarded: string | undefined
    newBalance: string | undefined,
    displayCheckWarning: boolean,
    discardReason: DiscardReason | undefined,
    comment: string | undefined;
    showComment: boolean;
    makeNewPartial: boolean;
    allowedRemovals: string[]
    patientId: string
    partials: InnerPartial[],
    newPartialWeightBefore: string,
    newPartialWeightAfter: string,
    newPartialAdminister: string,
    newWholeBalance: string | undefined
    bottleLabel: string
    noPartials: boolean
}

class PartialAdminister extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: any) {
        super(props);

        const partials: InnerPartial[] = []

        this.props.partials?.map((partial: Partials) => {
            partials.push({
                bottleLabel: partial.name,
                inventory: partial.inventory,
                discardUsed: false,
                weightBefore: "",
                weightAfter: "",
                amount: ""
            })
            return null;
        })

        //todo this logic and the rendering logic needs some love
        let noPartials = true;
        if (partials && partials.length > 0) {
            noPartials = false
        }

        const marking = this.newMarking(this.props.partials)


        //TODO -> check if there are current partials with the same name? if that is the case increment it by one
        //TODO fetch from backend

        this.state = {
            spinner: false,
            inputDiscarded: undefined,
            newBalance: undefined,
            displayCheckWarning: false,
            discardReason: undefined,
            showComment: false,
            comment: undefined,
            makeNewPartial: false,
            allowedRemovals: [],
            patientId: "",
            partials,
            newPartialWeightBefore: "",
            newPartialWeightAfter: "",
            newPartialAdminister: "",
            newWholeBalance: "",
            bottleLabel: marking,
            noPartials: noPartials
        }
    }

    private newMarking(partials: Partials[] | undefined): string {
        function containsMarking(s: string, part: Partials[] | undefined) {
            return part?.find((p) => p.name === s)
        }

        let marking = new Date().toISOString().substring(0, 10);
        let count = 1;

        while (containsMarking(marking + "-" + count, partials)) {
            count++;
        }

        return marking + "-" + count;
    }

    render() {
        if (this.state.spinner) {
            return this.getSpinner()
        }
        const type = UserSessionUtilService.getResolution() === Resolution.Desktop ? "text" : "number"
        const {tr} = this;

        const commentFlip = <div
            aria-label={'show hide comment'}
            className={"pt-2"}
            title="Lägg till kommentar">
            <button className={"btn btn-tiny-expand"} onClick={(e) => this.flipComment(e)}>
                {this.state.showComment ? "-" : "+"}
            </button>
        </div>;

        const commentField = <div>
            <div className={"row m-0 pt-2"}>
                <div className={"pt-2 pr-2"}>Kommentar:</div>
                {commentFlip}
            </div>
            {
                this.state.showComment ? <React.Fragment>
                    <div className={"row m-0"}>
                        <textarea value={this.state.comment}
                                  onChange={(e) => this.handleChangeComment(e)}
                                  className={"max-size-input"}
                        />
                    </div>
                </React.Fragment> : <div/>
            }
        </div>

        const warningStuff = this.state.displayCheckWarning ? <div>
            <div className={"row m-0 p-0 pb-3 justify-content-center"}>
                <h5 className={"innermodal-warning-text"}>Är du säker på att antalet är korrekt?</h5>
            </div>
            <div className={"row m-0 pr-4 justify-content-end"}>
                <button className={"btn btn-red"} onClick={() => this.submit(true)}>Jag är säker</button>
                <button className={"btn btn-tage-default-cancel  ml-2 mr-2"}
                        onClick={() => this.closeWarning()}>Avbryt
                </button>
            </div>
        </div> : "";

        const submitCancelButtons = this.state.displayCheckWarning ? "" :
            <div className={"row m-0 pr-4 pt-2 justify-content-end"}>
                <button aria-label={'ok'}
                        className={"btn btn-tage-default mr-2"} onClick={() => this.flipWholeBottle()}>
                    Hel flaska
                </button>

                <button aria-label={'ok'}
                        className={"btn btn-tage-default"} onClick={() => this.submit()}>
                    OK
                </button>
                <button aria-label={'cancel'}
                        className={"btn btn-tage-default-cancel ml-2 mr-2"}
                        onClick={this.props.closeModal}>
                    Avbryt
                </button>
            </div>;

        let makeNewButton = <div></div>

        let makeNewElement = <div></div>
        if (this.state.makeNewPartial) {
            makeNewElement = this.makeNewPartial(this.state.bottleLabel);
        }

        let partials: any[] = [];
        if (this.state.partials && this.state.partials.length > 0) {
            makeNewButton = <div className={"row m-0 pb-2 "}>
                <div className={"col-2"}></div>
                <div className={"col m-0 p-0"}>
                    <div className={"row m-0 p-0"}>
                        <button onClick={() => this.flipMakeNew()}
                                className={"btn btn-tage-default-cancel ml-1"}>{tr("Öppna ny flaska")}</button>
                    </div>
                </div>
            </div>;

            partials = this.state.partials.map((part) => {
                let className = "btn btn-invis-bg b-0 float-button"
                if (this.shouldBeDisabled(part.bottleLabel)) {
                    className = className + " p-0"
                } else {
                    className = className + " pixelpad"
                }


                return <React.Fragment key={part.bottleLabel}>
                    <div className={"row"}>
                        <div className={"col-2 pr-0 text-right"}>
                            <button
                                disabled={this.shouldBeDisabled(part.bottleLabel)}
                                className={className}><img
                                alt={"Delete"}
                                height={this.shouldBeDisabled(part.bottleLabel) ? 27 : 18}
                                aria-label={"delete copy"}
                                title={tr("Kan inte hitta / kassera delmängd")}
                                onClick={() => this.removePartial(part.bottleLabel)}
                                src={this.shouldBeDisabled(part.bottleLabel) ? DeleteLogoDisabled : DeleteLogo}/>
                            </button>
                        </div>
                        <div className={"col"}>
                            <div className={"row m-0 p-0"}>
                                <div>
                                    {"Öppnad :"+part.bottleLabel}
                                </div>
                            </div>
                        </div>
                        <div className={"col-2"}></div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-2"}></div>
                        <div className={"col"}>
                            <div className={"row m-0 p-0"}>
                                Vikt före:
                            </div>
                            <label className={"label-full-width"}>
                                <input type={type} className={"partial-input"} value={part.weightBefore}
                                       onChange={(e) => this.updatePartWeightBefore(e, part.bottleLabel)}/>
                                gram
                            </label>
                        </div>
                        <div className={"col-2"}></div>
                    </div>

                    <div className={"row pt-2"}>
                        <div className={"col-2"}></div>
                        <div className={"col"}>
                            <div className={"row m-0 p-0"}>
                                Vikt efter:
                            </div>
                            <label className={"label-full-width"}>
                                <input type={type} className={"partial-input"} value={part.weightAfter}
                                       onChange={(e) => this.updatePartWeightAfter(e, part.bottleLabel)}/>
                                gram
                            </label>
                        </div>
                        <div className={"col-2"}></div>
                    </div>

                    <div className={"row pt-2 pb-2"}>
                        <div className={"col-2"}></div>
                        <div className={"col"}>
                            <div className={"row m-0 p-0"}>
                                Använd mängd:
                            </div>
                            <label className={"label-full-width"}>
                                <input type={type} className={"partial-input"} value={part.amount}
                                       onChange={(e) => this.updatePartAmount(e, part.bottleLabel)}/>
                                milliliter
                            </label>
                        </div>
                        <div className={"col-2"}></div>
                    </div>

                    <div className={"row pt-1"}>
                        <div className={"col-2"}></div>
                        <div className={"col"}>
                            <div className={"row m-0 p-0"}>
                                <input checked={part.discardUsed}
                                       onChange={() => this.updateEmptyAndDiscarded(part.bottleLabel)}
                                       type={"checkbox"}/>{tr("Flaskan är tom och slängs")}
                            </div>
                        </div>
                        <div className={"col-2"}></div>
                    </div>
                    <div className={"row pb-3 pt-1"}>
                        <div className={"col-2"}></div>
                        <div className={"col"}>
                            <div className={"row m-0 p-0"}>
                                <button onClick={() => this.allowDeletion(part.bottleLabel)}
                                        className={"btn btn-invis-bg pb-0 pt-0 pl-0 pr-1 mb-1"}>
                                    <img
                                        alt={"unlock"}
                                        height={18}
                                        src={this.shouldBeDisabled(part.bottleLabel) ? Unlock : UnlockLocked}
                                    />
                                </button>
                                <div className={"pt-1"}>{tr("Flaskan är försvunnen")}</div>
                            </div>
                        </div>
                        <div className={"col-2"}></div>
                    </div>
                </React.Fragment>
            })
        } else {
            partials.push(this.makeNewPartial(this.state.bottleLabel))
        }


        return (<div className={"container m-0 p-0 partial-container"}>
                <div className={"row"}>
                    <div className={"col pl-5 pb-3 neg-padding-knarkbok"}>
                        <div>
                            {tr("Öppnade enheter: ")}{this.props.partials ? this.props.partials.length : "0"}
                        </div>
                    </div>
                </div>
                {makeNewButton}
                {makeNewElement}
                <div className={"row pt-2 pb-2"}>
                    <div className={"col-2 pr-0 text-right"}></div>
                    <div className={"col"}>
                        <div className={"row m-0 pb-1"}>
                            Patient-ID / Ärendenummer:
                        </div>
                        <div className={"row m-0 p-0"}>
                            <input aria-label={'patient id / case number'}
                                   value={this.state.patientId}
                                   onChange={(e) => this.updatePatientId(e)}
                                   type={"text"}/>
                        </div>
                    </div>
                    <div className={"col-2"}></div>
                </div>
                <div className={"row pb-2"}>
                    <div className={"col-2 pr-0 text-right"}></div>
                    <div className={"col"}>
                        <div className={"row m-0 pb-1"}>
                            {tr("Nytt saldo (hela flaskor)")}:
                        </div>
                        <div className={"row m-0 p-0"}>
                            <input aria-label={'patient id / case number'}
                                   value={this.state.newWholeBalance}
                                   onChange={(e) => this.updateNewWholeBalance(e)}
                                   type={"text"}/>
                        </div>
                    </div>
                    <div className={"col-2"}></div>
                </div>
                {
                    partials
                }
                <div className={"row"}>
                    <div className={"col-2"}></div>
                    <div className={"col"}>
                        {commentField}
                    </div>
                    <div className={"col-2"}></div>
                </div>
                <div className={"row"}>

                    {warningStuff}
                    <div className={"col-2"}></div>
                    <div className={"col"}>
                        {submitCancelButtons}
                    </div>
                    <div className={"col-2"}></div>
                </div>
            </div>

        );
    }

    private makeNewPartial(marking: string) {
        const type = UserSessionUtilService.getResolution() === Resolution.Desktop ? "text" : "number"
        return <div key={marking}>
            <div className={"row"}>
                <div className={"col-2 pr-0 text-right"}>
                </div>
                <div className={"col"}>
                    <div className={"row m-0 p-0"}>
                        Märk flaskan med:
                    </div>
                    <input disabled={true} value={marking}/>
                </div>
                <div className={"col-2"}></div>
            </div>

            <div className={"row pt-2"}>
                <div className={"col-2"}></div>
                <div className={"col"}>
                    <div className={"row m-0 p-0"}>
                        Vikt före:
                    </div>
                    <label className={"label-full-width"}>
                        <input type={type} className={"partial-input"} value={this.state.newPartialWeightBefore}
                               onChange={(e) => this.updateNewPartialBefore(e)}/>
                        gram
                    </label>
                </div>
                <div className={"col-2"}></div>
            </div>

            <div className={"row pt-2"}>
                <div className={"col-2"}></div>
                <div className={"col"}>
                    <div className={"row m-0 p-0"}>
                        Vikt efter:
                    </div>
                    <label className={"label-full-width"}>
                        <input type={type} className={"partial-input"} value={this.state.newPartialWeightAfter}
                               onChange={(e) => this.updateNewPartialAfter(e)}/>
                        gram
                    </label>
                </div>
                <div className={"col-2"}></div>
            </div>

            <div className={"row pt-2 pb-2"}>
                <div className={"col-2"}></div>
                <div className={"col"}>
                    <div className={"row m-0 p-0"}>
                        Använd mängd:
                    </div>
                    <label className={"label-full-width"}>
                        <input type={type} className={"partial-input"} value={this.state.newPartialAdminister}
                               onChange={(e) => this.updateNewPartialAdminister(e)}/>
                        milliliter
                    </label>
                </div>
                <div className={"col-2"}></div>
            </div>
        </div>
    }

    private getSpinner() {
        return <div aria-label={"administer-drug-spinner"}
                    className={"spinner"}/>;
    }

    private submit = async (forceSubmit?: boolean) => {
        let path = ""
        if (this.state.makeNewPartial || this.state.noPartials) {
            path = "/api/v1/knarkbok/partialevents/open"
        } else {
            path = "/api/v1/knarkbok/partialevents/"
        }
        const url = EventBackendService.getUrl2(path);

        let payload = {
            drugId: this.props.book.drug.id,
            binderId: this.props.book.binderId,
            organisationId: this.props.organisation.organisationId,
            bookId: this.props.book.bookId,
            amount: this.state.newPartialAdminister,
            weightBefore: this.state.newPartialWeightBefore,
            weightAfter: this.state.newPartialWeightAfter,
            patientIdArendenummer: this.state.patientId,
            comment: this.state.comment,
            bottleLabel: this.state.bottleLabel,
            newBalance: this.state.newWholeBalance,
            partials: this.state.partials
        };

        console.log(payload)
        this.setState({
            spinner: true,
        });

        await this.context.post(url, JSON.stringify(payload), {
            success: '',
            failure: ''
        })


        this.setState({
            spinner: false,
        });

        this.props.closeModal()
    }


    private flipComment(e: React.MouseEvent<HTMLButtonElement>): void {
        e.preventDefault();
        this.setState({showComment: !this.state.showComment})
    }

    private handleChangeComment(e: React.ChangeEvent<HTMLTextAreaElement>) {
        this.setState({comment: e.currentTarget.value});
    }

    private closeWarning() {
        this.setState({displayCheckWarning: false})
    }

    tr(s: string) {
        return TranslationService.translation(s);
    }

    private removePartial(name: string) {
        window.confirm("Detta kommer permanent att radera :" + name + ", är du säker?")
    }

    private flipWholeBottle() {
        const {flipToAdminister} = this.props
        if (flipToAdminister) {
            flipToAdminister();
        }
    }

    private flipMakeNew() {
        this.setState({makeNewPartial: !this.state.makeNewPartial})
    }

    private shouldBeDisabled(name: string) {
        return !this.state.allowedRemovals.includes(name);
    }

    private allowDeletion(name: string) {
        //TODO switch to commented version
        const removals = this.state.allowedRemovals;
        if (removals.includes(name)) {
            this.setState({allowedRemovals: []})
        } else {
            removals.push(name)
            this.setState({allowedRemovals: removals})
        }

        /*
        const removals = this.state.allowedRemovals;
        removals.push(name)
        this.setState({allowedRemovals: removals})

         */
    }

    private updateEmptyAndDiscarded(name: string) {
        const wanted = this.state.partials.find((part) => part.bottleLabel === name);
        if (wanted) {
            wanted.discardUsed = !wanted.discardUsed;
        }

        this.setState({...this.state});
    }

    private updatePatientId(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({patientId: e.currentTarget.value})
    }

    private updateNewPartialBefore(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({newPartialWeightBefore: e.currentTarget.value})
    }

    private updateNewPartialAfter(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({newPartialWeightAfter: e.currentTarget.value})
    }

    private updateNewPartialAdminister(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({newPartialAdminister: e.currentTarget.value})
    }

    private updateNewWholeBalance(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({newWholeBalance: e.currentTarget.value})
    }

    private updatePartWeightBefore(e: React.ChangeEvent<HTMLInputElement>, name: string) {
        const wanted = this.state.partials.find((part) => part.bottleLabel === name);
        if (wanted) {
            wanted.weightBefore = e.currentTarget.value;
        }

        this.setState({...this.state});
    }

    private updatePartWeightAfter(e: React.ChangeEvent<HTMLInputElement>, name: string) {
        const wanted = this.state.partials.find((part) => part.bottleLabel === name);
        if (wanted) {
            wanted.weightAfter = e.currentTarget.value;
        }

        console.log(this.state)

        this.setState({...this.state});
    }

    private updatePartAmount(e: React.ChangeEvent<HTMLInputElement>, name: string) {
        const wanted = this.state.partials.find((part) => part.bottleLabel === name);
        if (wanted) {
            wanted.amount = e.currentTarget.value;
        }

        this.setState({...this.state});
    }
}

export default PartialAdminister;