import React, {Component} from "react";
import {BackendContext} from "../../infra/BackendContext";
import {EventBackendService} from "../register/v2/EventBackendService";
import {Organisation} from "../model/Organisation";
import TranslationService from "../../infra/TranslationService";
import "./CreateUserModal.css"
import {TageResponse, UnregisteredStaff} from "../../pages/administration/user/UserAdministration";

interface props {
    organisation?: Organisation
    hideModal: () => void
    updateStaffList: () => Promise<void>
    unregisteredStaff?: UnregisteredStaff
    editing?: boolean
    creating?: boolean
}

interface state {
    firstName: string,
    lastName: string,
    staffId: string,
    missingMandatory: boolean
}

class CreateUnregisteredStaffModal extends Component<props, state> {
    static contextType = BackendContext;

    constructor(props: any) {
        super(props);
        if (this.props.editing) {
            this.state = ({
                firstName: this.props.unregisteredStaff?.firstName || "",
                lastName: this.props.unregisteredStaff?.lastName || "",
                staffId: this.props.unregisteredStaff?.id || "",
                missingMandatory: false
            })
        } else {
            this.state = ({
                firstName: "",
                lastName: "",
                staffId: "",
                missingMandatory: false
            })
        }
    }

    render() {
        const {tr} = this;
        const {firstName, lastName, missingMandatory} = this.state;
        const {editing, creating} = this.props;
        const headline = (this.props.editing && tr("Update unregistered staff")) ||
            (this.props.creating && tr("Create staff with no account")) ||
            (tr("default"));
        let firstNameClass = "";
        let lastNameClass = "";
        if (missingMandatory) {
            firstNameClass = firstName ? "" : "missing-mandatory"
            lastNameClass = lastName ? "" : "missing-mandatory"
        }
        //TODO make sure the creation modal is normal on a phone aswell
        return <div>
            <aside className="create-user-modal-background"
                   onClick={() => this.clear()}/>
            <div className={"create-unregistered-staff-modal justify-content-center"}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col justify-content-center"}>
                            <h4>
                                {headline}
                            </h4>
                        </div>
                    </div>
                    <div className={"row"}>
                        <div className={"col-4"}>
                            <div className={"row"}>
                                <div className={"col"}>
                                    {tr("firstName")}
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <input value={this.state.firstName}
                                           className={firstNameClass}
                                           aria-label={"first name"}
                                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleFirstName(e)}/>
                                </div>
                            </div>
                            <div className={"row pt-1"}>
                                <div className={"col"}>
                                    {tr("lastName")}
                                </div>
                            </div>
                            <div className={"row"}>
                                <div className={"col"}>
                                    <input value={this.state.lastName}
                                           className={lastNameClass}
                                           aria-label={"last name"}
                                           onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleLastName(e)}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"button-row"}>
                        <button className={"btn btn-tage-default-cancel"}
                                    aria-label={"cancel button"}
                                    onClick={() => this.clear()}>{tr("Cancel")}
                            </button>
                            <button className={"btn btn-tage-default"}
                                    aria-label={"confirm button"}
                                    onClick={() => this.submit()}>{editing ? tr("Update unregistered staff button") : creating ? tr("Create unregistered staff button") : "OK"}
                            </button>
                    </div>
                </div>
            </div>
        </div>
    }

    async componentDidMount() {
    }

    private tr(string: string): string {
        return TranslationService.translation(string)
    }

    private handleFirstName(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({firstName: e.currentTarget.value})
    }

    private handleLastName(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({lastName: e.currentTarget.value})
    }

    private mandatoryCheck(): boolean {
        const {firstName, lastName} = this.state;
        if (!firstName || !lastName) {
            this.setState({missingMandatory: true});
            return false;
        }
        return true;
    }

    private async submit() {
        let organisationId: string = this.props.organisation?.organisationId || "0";
        const {editing, creating} = this.props;
        const {firstName, lastName, staffId} = this.state;
        const path: string = `/api/v1/administration/unregistered-staff`
        const url: string = EventBackendService.getUrl2(path);
        if (!this.mandatoryCheck()) {
            return
        }
        let response;
        if (editing) {
            response = await this.context.put(url,
                JSON.stringify({
                    firstName,
                    lastName,
                    id: staffId
                }),
                {
                    success: this.tr("Edited unregistered staff member"),
                    failure: this.tr("Failed to edit unregistered staff member")
                }) as TageResponse;
        }
        if (creating) {
            response = await this.context.post(url,
                JSON.stringify({
                    firstName,
                    lastName,
                    organisationId,
                }),
                {
                    success: this.tr("Created unregistered staff member"),
                    failure: this.tr("Failed to create unregistered staff member")
                }) as TageResponse;
        }
        if (response && response.success) {
            await this.props.updateStaffList()
            this.clear();
        }
    }

    private clear() {
        this.props.hideModal();
    }
}

export default CreateUnregisteredStaffModal;