import React from 'react';
import {RouteComponentProps} from 'react-router-dom';
import "./SignupPage.css";
import TranslationService from "../../infra/TranslationService";
import PasswordInputField from "../fields/v2/PasswordInputField";
import {Organisation} from "../user/Organisation";
import CreatableSelect from "react-select/creatable";
import {MultiValue} from "react-select";
import {OptionType} from "../model/SelectOption";
import {EventBackendService} from "../register/v2/EventBackendService";
import {BackendContext, BackendInterface, Result} from "../../infra/BackendContext";

const SIGNUPAPI = "/api/v1/signup";

interface Role {
    roleId: string;
    mandatory?: boolean;
    selected?: boolean;
    translatedRoleName: string;
}

interface MatchParams {
    token: string;
}

interface props extends RouteComponentProps<MatchParams> {
}

interface state {
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    phone: string;
    language: string;
    signUpToken: string;
    roles: Role[];
    displayWarning: boolean;
    displayErrors: boolean;
    errors: string[];
    organisations: Organisation[];
    selectedOrganisations: MultiValue<OptionType>;
}

interface SignUpResponse {
    email: string,
    roles: Role[],
    organisations: Organisation[],

}

class SignupPage extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: props) {
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            password: "",
            phone: "",
            language: "sv_SE",
            signUpToken: this.props.match.params.token,
            roles: [],
            organisations: [],
            selectedOrganisations: [],
            displayWarning: false,
            displayErrors: false,
            errors: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.verifyPassword = this.verifyPassword.bind(this);
        this.transformToOrganisations = this.transformToOrganisations.bind(this);
        this.onChangeOrganisations = this.onChangeOrganisations.bind(this);
    }

    async componentDidMount() {
        TranslationService.initDefaultValues();
        this.setState({phone: "123 - 1234567"});

        const path = `${SIGNUPAPI}/${this.state.signUpToken}`;
        const url: string = EventBackendService.getUrl2(path);
        const response: any = await this.context.unsecureGet(url, {
            success: "",
            failure: ""
        }) as SignUpResponse;

        if (response !== undefined && response.data !== undefined) {
            this.setState({
                email: response.data.email,
                roles: response.data.roles || [],
                organisations: response.data.organisations || []
            });
        }
    }

    onChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        this.setState({[e.target.name]: e.target.value} as unknown as Pick<
            state,
            keyof state
        >);
    };

    selectRole = (e: React.ChangeEvent<HTMLInputElement>, roleId: string) => {
        this.state.roles
            .filter((role) => role.roleId === roleId)
            .map((role) => (role.selected = !role.selected));
        this.setState({roles: this.state.roles});
    };

    async handleSubmit(event: React.FormEvent<HTMLButtonElement> | React.FormEvent<HTMLFormElement>) {
        event.preventDefault();

        const {
            firstName,
            lastName,
            email,
            password,
            phone,
            language,
            roles,
            signUpToken,
            selectedOrganisations
        } = this.state;

        const organisations = selectedOrganisations.map(option => ({
            organisationId: option.value,
            organisationName: option.label
        }));

        const data = {
            firstName,
            lastName,
            email,
            password,
            phone,
            language,
            roles,
            signUpToken,
            organisations
        };

        localStorage.setItem("email", this.state.email);
        localStorage.setItem("password", this.state.password);
        const url: string = EventBackendService.getUrl2(SIGNUPAPI);
        const json: string = JSON.stringify(data);
        const result: Result = await this.context.unsecurePost(url, json);

        if (result.success) {
            window.location.href = "/";
        }

    }

    putDefaultSelected(roleId: string) {
        this.state.roles
            .filter((role) => role.roleId === roleId)
            .map((role) => (role.selected = true));
    }

    updatePassword(e: any) {
        e.preventDefault();
        const incoming = e.currentTarget.value;
        const validation = PasswordInputField.adheresToPasswordRestrictions(incoming);
        this.setState({errors: validation.errors, password: incoming});
    }

    verifyPassword(e: any) {
        e.preventDefault();
        const validation = PasswordInputField.adheresToPasswordRestrictions(
            this.state.password
        );
        if (!validation.valid) {
            this.setState({displayErrors: true, errors: validation.errors});
        } else {
            this.handleSubmit(e);
        }
    }

    render() {
        const options: any = this.transform(this.state.organisations);

        let language = TranslationService.toTranslationLanguage(this.state.language);
        return (
            <React.Fragment>
                <div className={"mainContainer container"}>
                    <div className={"col pt-2"}>
                        <div className={"row pb-3"}>
                            <div className={"col-12 col-md-4 text-md-right text-left"}/>
                            <div className={"col-12 col-md-8"}>
                                <h2>{TranslationService.translateTo("Fyll i dina uppgifter", language)}</h2>
                            </div>
                        </div>
                        <div className={"row pb-2"}>
                            <div className={"col-12 col-md-4 text-md-right text-left"}/>
                            <div className={"col-12 col-md-8"}>
                                {TranslationService.translateTo("mandatory.fields,in.form", language)}
                            </div>
                        </div>

                        <div className={"row pb-2"}>
                            <div className={"col-12 col-md-4 text-md-right text-left"}>Email:</div>
                            <div className={"col-12 col-md-8"}>
                                <div>{this.state.email}</div>
                            </div>
                        </div>
                        <div className={"row pb-2"}>
                            <div className={"col-12 col-md-4 text-md-right text-left"}>
                                {TranslationService.translateTo("Språk", language)}:
                            </div>
                            <div className={"col-12 col-md-8"}>
                                <select style={{width: "265px", height: "30px"}} name={"language"} required
                                        onChange={(e) => this.onChange(e)}>
                                    <option value={"sv_SE"}>Svenska</option>
                                    <option value={"en_GB"}>English</option>
                                </select>
                            </div>
                        </div>

                        <div className={"row pb-2"}>
                            <div className={"col-12 col-md-4 text-md-right text-left"}>
                                {TranslationService.translateTo("Förnamn", language)}:
                                *
                            </div>
                            <div className={"col-12 col-md-8"}>
                                <input
                                    size={30}
                                    name={"firstName"}
                                    aria-label={"firstName"}
                                    type={"text"}
                                    required
                                    placeholder={this.state.firstName}
                                    onChange={(e) => this.onChange(e)}
                                />
                            </div>
                        </div>

                        <div className={"row pb-2"}>
                            <div className={"col-12 col-md-4 text-md-right text-left"}>
                                {TranslationService.translateTo("Efternamn", language)}:
                                *
                            </div>
                            <div className={"col-12 col-md-8"}>
                                <input
                                    size={30}
                                    name={"lastName"}
                                    aria-label={"lastName"}
                                    type={"text"}
                                    required
                                    placeholder={this.state.lastName}
                                    onChange={(e) => this.onChange(e)}
                                />
                            </div>
                        </div>

                        <div className={"row pb-2"}>
                            <div className={"col-12 col-md-4 text-md-right text-left"}>
                                {TranslationService.translateTo("Lösenord", language)}:
                                *
                            </div>
                            <div className={"col-12 col-md-8"}>
                                <PasswordInputField
                                    size={30}
                                    errors={this.state.errors}
                                    displayErrors={this.state.displayErrors}
                                    mandatory
                                    password={this.state.password}
                                    update={(value: any) => this.updatePassword(value)}
                                />
                            </div>
                        </div>

                        <div className={"row pb-2"}>
                            <div className={"col-12 col-md-4 text-md-right text-left"}>
                                {TranslationService.translateTo("Telefon", language)}:
                            </div>
                            <div className={"col-12 col-md-8"}>
                                <input size={30}
                                    aria-label={"phone"}
                                    type="tel"
                                    name="phone"
                                    placeholder={this.state.phone}
                                    onChange={(e) => this.onChange(e)}
                                />
                            </div>
                        </div>
                        <div className={"row pb-2"}>
                            <div className={"col-12 col-md-4 text-md-right text-left"}>
                                {TranslationService.translateTo("Arbetsplats", language)}:
                            </div>
                            <div className={"col-12 col-md-8"}>
                                <div style={{width: "270px"}} data-testid="workplace-select">
                                    <CreatableSelect
                                        options={options}
                                        isMulti={true}
                                        onChange={this.onChangeOrganisations}
                                        value={this.state.selectedOrganisations}
                                        aria-label={"select.label"}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={"row pb-2"}>
                            <div className={"col-12 col-md-4 text-md-right text-left"}>
                                {TranslationService.translateTo("Område", language)}:
                                *
                            </div>
                            <div className={"col-12 col-md-8"}>
                                {this.state.roles.map((role) => (
                                    <ul style={{paddingLeft: 0}} key={role.roleId}>
                                        {!!role.mandatory ? this.putDefaultSelected(role.roleId) : ""}
                                        <input
                                            name={"role"}
                                            type={"checkbox"}
                                            checked={!!role.selected}
                                            required={!!role.mandatory}
                                            value={role.roleId}
                                            onChange={(event) => this.selectRole(event, role.roleId)}
                                        />
                                        <span style={{paddingLeft: 10}}>
                      {TranslationService.translateTo(role.translatedRoleName, language)}
                    </span>
                                    </ul>
                                ))}
                            </div>
                        </div>
                        <div className={"row pb-2 pt-2"}>
                            <div className={"col-12 col-md-4"}/>
                            <div className={"col-12 col-md-8"}>
                                <button
                                    aria-label={"submitButton"}
                                    value={"Submit"}
                                    id={"signupButton"}
                                    className={"btn btn-signup"}
                                    onClick={this.verifyPassword}
                                >
                                    Submit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private onChangeOrganisations(selectedOptions: MultiValue<OptionType>) {
        this.setState({selectedOrganisations: [...selectedOptions]})

    }

    private transform(organisations: Organisation[]): OptionType[] {
        let currentOptions: OptionType[] = [];
        for (let organisation of organisations) {
            let currentOption = {label: organisation.organisationName, value: organisation.organisationId};
            currentOptions.push(currentOption);
        }
        return currentOptions;
    }

    private transformToOrganisations(options: MultiValue<OptionType>): Organisation[] {
        let organisations: Organisation[] = [];
        options.forEach(option => {
            let organisation: Organisation = {organisationId: option.value, organisationName: option.label};
            organisations.push(organisation);
        })
        return organisations;
    }
}

export default SignupPage;
