import React, {ReactElement} from "react";
import {BackendContext, BackendInterface, Result} from "../../../../infra/BackendContext";
import {EventBackendService} from "../../../register/v2/EventBackendService";
import {TageResponse} from "../../../../pages/administration/user/UserAdministration";
import {Role} from "./Role";
import {Organisation} from "./Organisation";
import {OrganisationRoles} from "./OrganisationRoles";
import "./Selector.css";

export interface RolesResponse extends TageResponse {
    data: {
        roles: Role[]
    }
}

export interface OrganisationsResponse extends TageResponse {
    data: Organisation[]
}

export interface OrganisationRolesResponse extends TageResponse {
    data: OrganisationRoles
}

interface props {
    userName: string
}

interface state {
    availableRoles?: Role[]
    availableOrganisations?: Organisation[],
    currentOrganisations?: OrganisationRoles,

    selectedRole?: Role
    selectedOrganisation?: Organisation,
    organisationFilter: string
}

class ManageUsers extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: Readonly<props>) {
        super(props);

        this.state = {
            availableRoles: undefined,
            availableOrganisations: undefined,
            currentOrganisations: undefined,
            selectedRole: undefined,
            selectedOrganisation: undefined,
            organisationFilter: ''
        }
    }

    componentDidMount() {
        this.getCurrentOrganisations();
        this.getAvailableRoles();
        this.getAvailableOrganisations();
    }

    render(): ReactElement {
        let currentOrganisationsRoles: React.JSX.Element;
        if (this.state.currentOrganisations === undefined) {
            currentOrganisationsRoles = <div>Waiting for current organisations and roles</div>
        } else {
            currentOrganisationsRoles = this.renderCurrentOrganisationRoles(this.state.currentOrganisations);
        }

        let availableRoles: React.JSX.Element;
        if (this.state.availableRoles === undefined) {
            availableRoles = <div>Waiting for available roles</div>
        } else {
            availableRoles = this.renderAvailableRoles(this.state.availableRoles);
        }

        let availableOrganisations: React.JSX.Element;
        const organisations = this.state.availableOrganisations;
        if (organisations === undefined) {
            availableOrganisations = <div>Waiting for available organisations</div>
        } else {
            availableOrganisations = this.renderAvailableOrganisations(organisations);
        }

        return <div className={"row"}>
            <div className={"col-5"}>
                {currentOrganisationsRoles}
            </div>
            <div className={"col-2"}/>
            <div className={"col-5"}>
                {availableRoles}
                {availableOrganisations}
            </div>
        </div>
    }

    private getCurrentOrganisations() {
        const userName: string = this.props.userName;
        const path: string = '/api/v3/backoffice/user/organisations/roles/' + userName;
        const url: string = EventBackendService.getUrl2(path);
        const currentOrganisations: Promise<OrganisationRolesResponse> = this.context.get(url);

        currentOrganisations.then((organisationsResponse) => {
            if (organisationsResponse.success) {
                this.setState({
                    currentOrganisations: organisationsResponse.data
                })
            }
        });
    }

    private getAvailableRoles() {
        const path: string = '/api/v3/backoffice/user/roles/available';
        const url: string = EventBackendService.getUrl2(path);
        const availableRoles: Promise<RolesResponse> = this.context.get(url);

        availableRoles.then((availableRolesResponse) => {
            if (availableRolesResponse.success) {
                if (availableRolesResponse.data !== undefined) {
                    this.setState({
                        availableRoles: availableRolesResponse.data.roles
                    })
                }
            }
        });
    }

    private getAvailableOrganisations() {
        const userName: string = this.props.userName;
        const path: string = '/api/v3/backoffice/user/organisations/available/' + userName;
        const url: string = EventBackendService.getUrl2(path);
        const availableOrganisations: Promise<OrganisationsResponse> = this.context.get(url);

        availableOrganisations.then((availableOrganisationsResponse) => {
            if (availableOrganisationsResponse.success) {
                this.setState({
                    availableOrganisations: availableOrganisationsResponse.data
                })
            }
        });
    }

    private renderCurrentOrganisationRoles(currentOrganisationRoles: OrganisationRoles): React.JSX.Element {
        const selectedOrganisation: Organisation | undefined = this.state.selectedOrganisation;
        const selectedRole: Role | undefined = this.state.selectedRole;

        const currentOrgs: React.JSX.Element[] = currentOrganisationRoles.organisations.map((orgRoles: {
            organisation: Organisation;
            roles: Role[]
        }) => {
            const organisation: Organisation = orgRoles.organisation;
            const organisationName: string = organisation.name;

            const oragisationHeader: React.JSX.Element = <div className={"row"}>
                <div
                    key={organisation.id}
                    className={"col"}>
                    <h3>{organisationName}</h3>
                </div>
                <button key={'remove-' + organisationName + '-from-user'}
                        aria-label={'remove-' + organisationName + '-from-user'}
                        onClick={async () => this.removeOrganisationFromUser(organisation)}>
                    {"->"}
                </button>
            </div>;

            const roles = orgRoles.roles.map((role) => {
                const roleName = role.name;
                let selected: string = '';
                if (selectedOrganisation === organisation && selectedRole === role) {
                    selected = 'selected';
                }
                const className: string = 'row ' + selected;

                return <div
                    key={role.id}
                    className={className}
                    aria-label={organisationName + ' ' + roleName}
                    onClick={() => this.selectRoleInOrganisation(role, organisation)}
                >
                    {roleName}
                </div>
            });

            const removeAndAssignButtons = <div>
                <button key={'remove-role-from-' + organisationName}
                        aria-label={'remove-role-from-' + organisationName}
                        onClick={async () => this.removeRoleFromUserInOrganisation(organisation)}>
                    {"->"}
                </button>
                <button key={'assign-role-to-' + organisationName}
                        aria-label={'assign-role-to-' + organisationName}
                        onClick={async () => this.assignSelectedRoleToOrg(organisation)}>
                    {"<-"}
                </button>
            </div>;

            return <div>
                <div key={organisationName}
                     className={"row organisation-card"}>
                    {oragisationHeader}
                </div>
                <div>Space</div>
                <div className={"row role-card"}>
                    <div className={'col'}>
                        {roles}
                    </div>
                    <div className={'col'}>
                        {removeAndAssignButtons}
                    </div>
                </div>
                <div>Space before next organisation</div>
            </div>
        });

        const usersName: string = currentOrganisationRoles.name;

        return <div>
            <h1>{usersName}</h1>
            {currentOrgs}
        </div>;
    }

    private renderAvailableRoles(availableRoles: Role[]): React.JSX.Element {
        const selectedOrganisation: Organisation | undefined = this.state.selectedOrganisation;
        const selectedRole: Role | undefined = this.state.selectedRole;

        const roles: React.JSX.Element[] = availableRoles.map((role: Role) => {
            let selected: string = '';
            if (selectedOrganisation === undefined && selectedRole === role) {
                selected = 'selected';
            }
            const className: string = 'row ' + selected;

            return <div
                key={role.id}
                className={className}
                onClick={() => this.selectAvailableRole(role)}
            > {role.name} </div>
        });

        return <div>
            <h1>Available roles</h1>
            {roles}
        </div>;
    }

    private renderAvailableOrganisations(availableOrganisations: Organisation[]): React.JSX.Element {
        const selectedOrganisation: Organisation | undefined = this.state.selectedOrganisation;
        const selectedRole: Role | undefined = this.state.selectedRole;

        const filter: React.JSX.Element = <div>
            <input aria-label={'Filter organisations'}
                   placeholder={'Filter organisations'}
                   type={'text'}
                   onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.onOrganisationFilterChange(event)}/>
        </div>;

        let filteredOrganisations: Organisation[] = availableOrganisations;
        const organisationFilter: string = this.state.organisationFilter;
        if (organisationFilter.length > 0) {
            filteredOrganisations = availableOrganisations.filter((organisation) => {
                const wanted: string = organisationFilter.toLowerCase();
                const candidate: string = organisation.name.toLowerCase();

                return candidate.includes(wanted)
            });
        }

        const organisations: React.JSX.Element[] = filteredOrganisations.map((organisation: Organisation) => {
            let selected: string = '';
            if (selectedOrganisation === organisation && selectedRole === undefined) {
                selected = 'selected';
            }
            const className: string = 'row ' + selected;

            return <div
                key={organisation.id}
                className={className}
                onClick={async () => this.selectOrganisation(organisation)}
            >
                {organisation.name}
            </div>
        });

        const assignOrgToUser: React.JSX.Element = <button aria-label={'assign-organisation-to-user'}
                                                           onClick={async () => this.assignSelectedOrganisationToUser()}>
            {"<-"}
        </button>

        return <div>
            <h1>Available organisations</h1>
            <h1>Should be limited to 20 visible rows</h1>
            <div className={"row"}>
                <div className={'col'}>
                    {assignOrgToUser}
                </div>
                <div className={'col'}>
                    {filter}
                    {organisations}
                </div>
            </div>
        </div>;
    }

    private selectRoleInOrganisation(role: Role, organisation: Organisation) {
        this.setState({
            selectedOrganisation: organisation,
            selectedRole: role
        });
    }

    private selectAvailableRole(role: Role) {
        this.setState({
            selectedOrganisation: undefined,
            selectedRole: role
        });
    }

    private selectOrganisation(organisation: Organisation) {
        this.setState({
            selectedOrganisation: organisation,
            selectedRole: undefined
        });
    }

    private removeRoleFromUserInOrganisation(organisation: Organisation) {
        if (organisation === this.state.selectedOrganisation &&
            this.state.selectedRole !== undefined) {
            const role: Role = this.state.selectedRole;

            const userName: string = this.props.userName;
            const path: string = '/api/v3/backoffice/user/organisation/role/' + userName + '/' + organisation.id + '/' + role.id;
            const url: string = EventBackendService.getUrl2(path);
            const deleted: Promise<TageResponse> = this.context.delete(url);

            deleted.then((response) => {
                if (response.success) {
                    this.setState({
                        selectedOrganisation: undefined,
                        selectedRole: undefined
                    })
                }
                this.getCurrentOrganisations();
            });
        }
    }

    private async assignSelectedRoleToOrg(organisation: Organisation) {
        const selectedRole: Role | undefined = this.state.selectedRole;
        if (selectedRole !== undefined) {
            const roleId = selectedRole.id;
            const organisationId = organisation.id;
            const userName = this.props.userName;

            await this.assignRoleToOrg(roleId, organisationId, userName);
        }
    }

    private async assignRoleToOrg(roleId: string, organisationId: string, userName: string) {
        const url: string = '/api/v3/backoffice/user/organisation/role'
        const payload: {
            roleId: string,
            organisationId: string,
            userName: string
        } = {
            roleId: roleId,
            organisationId: organisationId,
            userName: userName
        };
        const json: string = JSON.stringify(payload);
        const result: Result = await this.context.post(url, json);

        if (result.success) {
            this.getCurrentOrganisations();
        }
    }

    private async assignSelectedOrganisationToUser() {
        const organisation: Organisation | undefined = this.state.selectedOrganisation;
        if (organisation !== undefined) {
            const url: string = '/api/v3/backoffice/organisation/user'
            const userName = this.props.userName;
            const organisationId = organisation.id;
            const payload: {
                organisationId: string,
                userName: string
            } = {
                organisationId: organisationId,
                userName: userName
            };
            const json: string = JSON.stringify(payload);
            const result: Result = await this.context.post(url, json);

            if (result.success) {
                this.getCurrentOrganisations();
            }
        }
    }

    private removeOrganisationFromUser(organisation: Organisation) {
        const userName: string = this.props.userName;
        const path: string = '/api/v3/backoffice/user/organisation/' + userName + '/' + organisation.id;
        const url: string = EventBackendService.getUrl2(path);
        const deleted: Promise<TageResponse> = this.context.delete(url);

        deleted.then((response) => {
            if (response.success) {
                this.setState({
                    selectedOrganisation: undefined,
                    selectedRole: undefined
                })
            }
            this.getCurrentOrganisations();
        });
    }

    private onOrganisationFilterChange(event: React.ChangeEvent<HTMLInputElement>) {
        const newFilter: string = event.target.value;

        this.setState({
            organisationFilter: newFilter
        })
    }
}

export default ManageUsers;
