import React from 'react';
import {BackendContext, BackendInterface} from "../../../../../infra/BackendContext";

interface Props {
}

interface State {
    message: string
}

class ForeignPatientReport extends React.Component<Props, State> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: Readonly<Props> | Props) {
        super(props);

        this.state = {
            message: ""
        }
    }

    render() {

        const message = this.state.message;
        return (
            <div>
                <button onClick={() => this.sendMail()}>
                    Create foreign patient report
                </button>
                <div>{message}</div>
            </div>
        );
    }

    private async sendMail() {
        const url: string = '/api/v1/backoffice/send/foreign/patient/report';

        await this.context.get(url, {
            success: "Ok",
            failure: ""
        });
        this.setState({
            message: "Create foreign patient report mail sent"
        });
    }
}

export default ForeignPatientReport;
