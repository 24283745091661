import React from 'react';
import {BinderId, DrugId} from "../models/Models";
import "../KnarkbokModal.css"
import {EventBackendService} from "../../register/v2/EventBackendService";
import {BackendContext, BackendInterface} from "../../../infra/BackendContext";
import TranslationService from "../../../infra/TranslationService";
import {User} from "../../model/User";

interface props {
    closeFocus: () => void,
    updateValue: (value: string, id: DrugId | BinderId | undefined, acceptDelivery?: boolean, allowPartials?: boolean) => void,
    id: DrugId | BinderId | undefined,
    displayString: string | undefined
    updateWhenDone?: () => {};
    mode?: KBControlPanelMode;
    acceptDelivery?: boolean;
    canEdit: boolean;
    canBeArchived?: boolean;
    allowPartials?: boolean;
    user?: User;
}

interface state {
    newName: string | undefined
    warningText: string;
    acceptDelivery: boolean;
    allowPartials: boolean;
}

export enum KBControlPanelMode {
    "drug",
    "binder"
}

class KBControlPanelEditPopup extends React.Component<props, state> {
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: any) {
        super(props);
        this.state = {
            newName: this.props.displayString,
            warningText: "",
            acceptDelivery: this.props.acceptDelivery || false,
            allowPartials: this.props.allowPartials || false
        }
    }

    render(): React.JSX.Element {
        let mode: KBControlPanelMode | undefined = this.props.mode;
        let headline: React.JSX.Element = <div/>
        let deleteButton: React.JSX.Element = <div/>
        let acceptDelivery: React.JSX.Element = <div/>;
        let warningText: string = this.state.warningText;
        const disabled: boolean = !this.props.canEdit;
        let allowPartials: React.JSX.Element = <div/>;
        const {tr} = this;

        if (mode === KBControlPanelMode.drug) {
            headline = <div className={"col"}>Radera läkemedel:</div>
            if (disabled) {
                warningText = 'Det finns transaktioner, läkemedlet kan inte byta namn eller raderas.'
            }

            deleteButton = <div className={"col"}>
                <button className={"btn btn-tage-default-red"}
                        disabled={disabled}
                        onClick={() => this.deleteDrug()}>
                    Radera
                </button>
            </div>

            allowPartials = <div className={"row m-0 pt-2"}>
                <div><input type={"checkbox"}
                            onClick={() => this.flipAllowPartials()}
                            checked={this.state.allowPartials}/>
                    {tr("Flaska som ska vägas")}
                </div>
            </div>
        }

        if (mode === KBControlPanelMode.binder) {
            let canBeArchived: boolean = true;
            if (this.props.canBeArchived !== undefined) {
                canBeArchived = this.props.canBeArchived;
            }

            headline = <div className={"col"}>Arkivera Journal:</div>
            deleteButton = <div className={"col"}>
                <button className={"btn btn-tage-default-red"}
                        onClick={() => this.deleteBinder()}
                        disabled={!canBeArchived}
                >
                    Arkivera
                </button>
            </div>

            acceptDelivery = <div className={"row m-0 pt-2"}>
                <div><input type={"checkbox"}
                            onClick={() => this.flipAcceptDelivery()}
                            checked={this.state.acceptDelivery}/>
                    Kan ta emot apoteksleverans
                </div>
            </div>
        }

        //TODO the background closeFocus only works in the parent element?
        return <div>
            <aside className="knarkbok-modal-background" onClick={this.props.closeFocus}/>
            <div className={"knarkbok-modal"}>
                <div className={"container"}>
                    <div className={"row pb-3"}>
                        <div className={"col"}>
                            Uppdatera namn:
                        </div>
                        {headline}
                    </div>
                    <div className={"row pb-3"}>
                        <div className={"col"}>
                            <input type={"text"}
                                   disabled={disabled}
                                   value={this.state.newName}
                                   onChange={(e) => this.handleChange(e)}
                            />
                        </div>
                        {deleteButton}
                    </div>
                    {allowPartials}
                    {acceptDelivery}
                    <div className={"row pb-3"}>
                        <div className={"col"}>
                            {warningText}
                        </div>
                    </div>
                    <div className={"row pr-4 justify-content-end pt-3"}>
                        <button className={"btn btn-tage-default mr-2"}
                                onClick={() => this.editValue()}
                        >
                            OK
                        </button>
                        <button className={"btn btn-tage-default-cancel"}
                                onClick={this.props.closeFocus}>
                            Avbryt
                        </button>
                    </div>
                </div>
            </div>
        </div>
    };

    private handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({newName: e.currentTarget.value});
    }

    private async editValue() {
        const {newName, acceptDelivery, allowPartials} = this.state;
        if (newName || allowPartials) {
            this.props.updateValue(newName ? newName : "", this.props.id, acceptDelivery, allowPartials);
            this.props.closeFocus();
        }
    }

    private async deleteDrug() {
        const path = `/api/v1/knarkbok/drugs/${this.props.id?.id}`
        const url = EventBackendService.getUrl2(path);

        const deleteResponse = await this.context.delete(url, {success: "Raderade läkemedel", failure: "Fel uppstod"})

        if (!deleteResponse.success) {
            this.setState({warningText: deleteResponse.data})
        } else {
            if (this.props.updateWhenDone) {
                this.props.updateWhenDone()
                this.props.closeFocus()
            }
        }
    }

    private async deleteBinder() {
        const path = `/api/v1/knarkbok/binders/${this.props.id?.id}`
        const url = EventBackendService.getUrl2(path);

        const deleteResponse = await this.context.delete(url, {
            success: "Arkiverade läkemedelsjournal",
            failure: "Misslyckades att arkivera läkemedelsjournal"
        })

        if (!deleteResponse.success) {
            this.setState({warningText: deleteResponse.data})
        } else {
            if (this.props.updateWhenDone) {
                this.props.updateWhenDone()
                this.props.closeFocus()
            }
        }
    }

    private flipAcceptDelivery() {
        this.setState({acceptDelivery: !this.state.acceptDelivery})
    }

    private flipAllowPartials() {
        this.setState({allowPartials: !this.state.allowPartials})
    }

    tr(s: string): string {
        return TranslationService.translation(s);
    }
}

export default KBControlPanelEditPopup;
