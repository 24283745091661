import {BackendInterface, Message, Result} from "./infra/BackendContext";
import {getSecurityToken} from "./infra/SecurityToken";
import {RESTMethods} from "msw";

let host: string;
if (process.env.REACT_APP_LOCALHOST === undefined) {
    host = '';
} else {
    host = process.env.REACT_APP_LOCALHOST;
}

export class BackendHandler {
    static getHandler(displaySuccess: (text?: string) => void, displayFailure: (text?: string) => void): BackendInterface {
        return {
            post: this.getPost(displaySuccess, displayFailure),
            unsecurePost: this.getUnsecurePost(displaySuccess, displayFailure),
            put: this.getPut(displaySuccess, displayFailure),
            delete: this.getDelete(displaySuccess, displayFailure),
            get: this.getGetMethod(displaySuccess, displayFailure),
            unsecureGet: this.getUnsecureGetMethod(displaySuccess, displayFailure),
            test: this.getTest(displaySuccess, displayFailure)
        }
    }

    private static getTest(displaySuccess: (text?: string) => void, displayFailure: (text?: string) => void) {
        return async (expectedResult: boolean, message?: Message): Promise<Result> => {
            if (expectedResult) {
                displaySuccess(message?.success);
            } else {
                displayFailure(message?.failure);
            }

            return {success: expectedResult, message: "this is a test", httpErrorCode: expectedResult ? 200 : 418}
        };
    }

    private static getGetMethod(displaySuccess: (text?: string) => void, displayFailure: (text?: string) => void) {
        return async (url: string, message?: Message): Promise<Result> => {
            const init = {
                method: RESTMethods.GET,
                headers: {
                    'X-Custom-header': getSecurityToken()
                },
                credentials: "include"
            };

            return this.send(url, init, displaySuccess, displayFailure, message)
        };
    }

    private static getUnsecureGetMethod(displaySuccess: (text?: string) => void, displayFailure: (text?: string) => void) {
        return async (url: string, message?: Message): Promise<Result> => {
            const init = {
                method: RESTMethods.GET,
            };

            return this.send(url, init, displaySuccess, displayFailure, message)
        };
    }

    private static async send(uri: string, init: {
                                  headers?: { "X-Custom-header": string };
                                  method: RESTMethods;
                                  credentials?: string
                              },
                              displaySuccess: (text?: string) => void,
                              displayFailure: (text?: string) => void,
                              message?: {
                                  success?: string | undefined;
                                  failure?: string | undefined
                              }): Promise<Result> {

        const url: string = uri.startsWith(host) ? uri : host + uri;
        //@ts-ignore //TODO fix init credentials string type
        const response: Response = await fetch(url, init);

        let body;
        try {
            body = await response.json();
        } catch (e) {
            console.log("e", e)
        }

        if (response.ok) {
            displaySuccess(message?.success);
            return {
                success: true,
                data: body,
                message: response.statusText
            }
        } else {
            displayFailure(message?.failure)
            return {
                success: false,
                message: response.statusText,
                httpErrorCode: response.status,
                data: body
            }
        }
    }

    private static getDelete(displaySuccess: (text?: string) => void, displayFailure: (text?: string) => void) {
        return async (url: string, message?: Message): Promise<Result> => {
            const init = {
                method: RESTMethods.DELETE,
                headers: {
                    'X-Custom-header': getSecurityToken()
                },
                credentials: "include",
            };

            return this.send(url, init, displaySuccess, displayFailure, message)
        };
    }

    private static getPut(displaySuccess: (text?: string) => void, displayFailure: (text?: string) => void) {
        return async (url: string, payload: any, message?: Message): Promise<Result> => {
            const init = {
                method: RESTMethods.PUT,
                headers: {
                    'X-Custom-header': getSecurityToken()
                },
                credentials: "include",
                body: payload
            };

            return this.send(url, init, displaySuccess, displayFailure, message)
        };
    }

    private static getPost(displaySuccess: (text?: string) => void, displayFailure: (text?: string) => void) {
        return async (url: string, payload: any, message?: Message): Promise<Result> => {
            const init = {
                method: RESTMethods.POST,
                headers: {
                    'X-Custom-header': getSecurityToken()
                },
                credentials: "include",
                body: payload
            };

            return this.send(url, init, displaySuccess, displayFailure, message)
        };
    }

    private static getUnsecurePost(displaySuccess: (text?: string) => void, displayFailure: (text?: string) => void) {
        return async (url: string, payload: any, message?: Message): Promise<Result> => {
            const init = {
                method: RESTMethods.POST,
                body: payload
            };

            return this.send(url, init, displaySuccess, displayFailure, message)
        };
    }
}