import {fetchMapOfStrings} from "./BackendService";
import publicTranslations from "./translations.json";
import {removeDuplicationMarker} from "../components/fields/v2/DuplicationUtility";
import {max_boundary, min_boundary} from "../components/statistics/v3/StatisticsNumberField";

interface publicTranslation {
    key: string,
    domain: string,
    value: string
}


enum TranslationLanguage {
    english = "en_GB",
    svenska = "sv_SE",
    finska = "fi_FI"
}

class TranslationService {
    private static translations: Map<string, string> = new Map();
    private static defaultEnglishTranslations: Map<string, string> = new Map();
    private static translationMapper: Map<TranslationLanguage, Map<string, string>> = new Map();
    private static domainToLang: Map<string, TranslationLanguage> = new Map();

    static toTranslationLanguage(value: string): TranslationLanguage | undefined {
        switch (value) {
            case TranslationLanguage.english:
                return TranslationLanguage.english;
            case TranslationLanguage.svenska:
                return TranslationLanguage.svenska;
            case TranslationLanguage.finska:
                return TranslationLanguage.finska;
            default:
                return undefined;
        }
    }


    static hasTranslation(key: string): boolean {
        return TranslationService.translations.has(key);
    }

    static translation(key: string): string {
        const filtered = removeDuplicationMarker(key);
        if (TranslationService.translations.size === 0) {
            TranslationService.addSwedishDefault();
        }

        if (key.includes(`.${min_boundary}.`)) {
            const val = key.split(`.${min_boundary}.`).pop();

            if (val) {
                return "≥ " + val;
            }
        }

        if (key.includes(`.${max_boundary}.`)) {
            const val = key.split(`.${max_boundary}.`).pop();

            if (val) {
                return "≤ " + val;
            }
        }

        let value: string | undefined;
        value = TranslationService.translations.get(filtered);

        if (value !== undefined) {
            return value;
        } else {
            return filtered;
        }
    }

    static translateTo(key: string, language: TranslationLanguage | undefined): string {
        if (language === undefined) {
            language = TranslationLanguage.svenska
        }
        const translations = TranslationService.translationMapper.get(language);
        const filtered = removeDuplicationMarker(key);

        let value: string | undefined;
        if (translations) {
            value = translations.get(filtered);
        }

        return value || filtered;
    }

    static translatePerDomain(key: string, domain: string): string {
        let removed = removeDuplicationMarker(key);
        let lang = TranslationService.domainToLang.get(domain);

        if (lang) {
            return TranslationService.translateTo(removed, lang);
        }

        return removed;
    }


    static publicTranslation(key: string, domain: string): string {
        const translation: publicTranslation | undefined = publicTranslations.find(translation => translation.key === key && translation.domain === domain);

        if (translation !== undefined) {
            return translation.value;
        }

        return key;
    }

    static async init() {
        const uri = '/api/v1/translations';
        try {
            TranslationService.translations = await fetchMapOfStrings(uri);
        } catch (e) {
            console.error(e);
        }

        TranslationService.initDefaultValues()
    }

    static initDefaultValues() {
        TranslationService.addSwedishDefault();
        TranslationService.addEnglishDefault();
        TranslationService.setupDomainToLangDefault();
        TranslationService.translationMapper.set(TranslationLanguage.svenska, TranslationService.translations);
        TranslationService.translationMapper.set(TranslationLanguage.english, TranslationService.defaultEnglishTranslations);
    }


    static addSwedishDefault() {
        TranslationService.translations.set("logout after inactivity", "Du har loggats ut på grund av inaktivitet");
        TranslationService.translations.set("unknownActionMessage", "Okänt formulär");
        TranslationService.translations.set("mandatory field is missing", "är obligatoriskt");
        TranslationService.translations.set("statistics.graph.empty.world", "Det går inte att visa fungerande statistik för världen med de här kriterierna");
        TranslationService.translations.set("statistics.graph.empty.user", "Det går inte att visa fungerande statistik med de här kriterierna");
        TranslationService.translations.set("supervisor.headline.reports", "Rapporter");
        TranslationService.translations.set("supervisor.headline.users", "Användare");
        TranslationService.translations.set("supervisor.headline.statistics", "Statistik");
        TranslationService.translations.set("mandatory.fields,in.form", "Obligatoriska fält är markerade med *");

        // Translations used in tests
        TranslationService.translations.set("ryggstick.type", "Typ");
        TranslationService.translations.set("a.text.field", "Ett textfält");
        TranslationService.translations.set("anaesthesia-experience.period.from", "Från");
        TranslationService.translations.set("anaesthesia-experience.experience.category.intensive_care", "Intensivvård");
        TranslationService.translations.set("anaesthesia-experience.experience.experience.intensive_care.postoperative", "Postop");

        //translations for public page
        //countersign
        TranslationService.translations.set("countersign", "Kontrasignering");

        TranslationService.translations.set("countersign.signer.name", "Kontrasignatör");
        TranslationService.translations.set("surgery", "Operation");
        TranslationService.translations.set("ondate", "den");
        TranslationService.translations.set("forUser", "åt");
        TranslationService.translations.set("thank you", "Tack");
        TranslationService.translations.set("First name", "Förnamn");
        TranslationService.translations.set("Last name", "Efternamn");

        TranslationService.translations.set("dops-surgery.general", "Allmänt");
        TranslationService.translations.set("dops-surgery.general.level", "Svårighetsgrad");
        TranslationService.translations.set("dops-surgery.general.level.easy", "Enkelt");
        TranslationService.translations.set("dops-surgery.general.level.medium", "Medelsvårt");
        TranslationService.translations.set("dops-surgery.general.level.difficult", "Svårt");

        TranslationService.translations.set("dops-surgery.general.location", "Plats");
        TranslationService.translations.set("dops-surgery.general.location.opd", "Mottagning");
        TranslationService.translations.set("dops-surgery.general.location.ward", "Avdelning");
        TranslationService.translations.set("dops-surgery.general.location.er", "Akutavdelning");
        TranslationService.translations.set("dops-surgery.general.location.or", "Operation");
        TranslationService.translations.set("dops-surgery.general.location.other", "Annan (ange i kommentar)");

        TranslationService.translations.set("dops-surgery.evaluation", "Utvärdering");


        TranslationService.translations.set("dops-surgery.evaluation.comprehension", "1. Förstår indikationen för ingreppet, känner till anatomin och tekniken");
        TranslationService.translations.set("dops-surgery.evaluation.comprehension.a", "ST-läkaren observerar annan läkare som utför ingreppet");
        TranslationService.translations.set("dops-surgery.evaluation.comprehension.b", "ST-läkaren utför ingreppet med proaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.comprehension.c", "ST-läkaren utför ingreppet med reaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.comprehension.d", "ST-läkaren utför ingreppet självständigt");
        TranslationService.translations.set("dops-surgery.evaluation.comprehension.e", "ST-läkaren utför ingreppet självständigt och har uppvisat kompetens i att själv handleda i liknande situationer");
        TranslationService.translations.set("dops-surgery.evaluation.comprehension.f", "Inte observerat");

        TranslationService.translations.set("dops-surgery.evaluation.information", "2. Informerat och fått godkännande från patienten");
        TranslationService.translations.set("dops-surgery.evaluation.information.a", "ST-läkaren observerar annan läkare som utför ingreppet");
        TranslationService.translations.set("dops-surgery.evaluation.information.b", "ST-läkaren utför ingreppet med proaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.information.c", "ST-läkaren utför ingreppet med reaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.information.d", "ST-läkaren utför ingreppet självständigt");
        TranslationService.translations.set("dops-surgery.evaluation.information.e", "ST-läkaren utför ingreppet självständigt och har uppvisat kompetens i att själv handleda i liknande situationer");
        TranslationService.translations.set("dops-surgery.evaluation.information.f", "Inte observerat");

        TranslationService.translations.set("dops-surgery.evaluation.preparation", "3. Förbereder ingreppet på ett adekvat sätt (inklusive hygienaspekter)");
        TranslationService.translations.set("dops-surgery.evaluation.preparation.a", "ST-läkaren observerar annan läkare som utför ingreppet");
        TranslationService.translations.set("dops-surgery.evaluation.preparation.b", "ST-läkaren utför ingreppet med proaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.preparation.c", "ST-läkaren utför ingreppet med reaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.preparation.d", "ST-läkaren utför ingreppet självständigt");
        TranslationService.translations.set("dops-surgery.evaluation.preparation.e", "ST-läkaren utför ingreppet självständigt och har uppvisat kompetens i att själv handleda i liknande situationer");
        TranslationService.translations.set("dops-surgery.evaluation.preparation.f", "Inte observerat");

        TranslationService.translations.set("dops-surgery.evaluation.anaesthesia", "4. Förbereder ingreppet med adekvat bedövning/premedicinering/anestesi (om det är aktuellt)");
        TranslationService.translations.set("dops-surgery.evaluation.anaesthesia.a", "ST-läkaren observerar annan läkare som utför ingreppet");
        TranslationService.translations.set("dops-surgery.evaluation.anaesthesia.b", "ST-läkaren utför ingreppet med proaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.anaesthesia.c", "ST-läkaren utför ingreppet med reaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.anaesthesia.d", "ST-läkaren utför ingreppet självständigt");
        TranslationService.translations.set("dops-surgery.evaluation.anaesthesia.e", "ST-läkaren utför ingreppet självständigt och har uppvisat kompetens i att själv handleda i liknande situationer");
        TranslationService.translations.set("dops-surgery.evaluation.anaesthesia.f", "Inte observerat");

        TranslationService.translations.set("dops-surgery.evaluation.technique", "5. Tekniskt skicklig");
        TranslationService.translations.set("dops-surgery.evaluation.technique.a", "ST-läkaren observerar annan läkare som utför ingreppet");
        TranslationService.translations.set("dops-surgery.evaluation.technique.b", "ST-läkaren utför ingreppet med proaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.technique.c", "ST-läkaren utför ingreppet med reaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.technique.d", "ST-läkaren utför ingreppet självständigt");
        TranslationService.translations.set("dops-surgery.evaluation.technique.e", "ST-läkaren utför ingreppet självständigt och har uppvisat kompetens i att själv handleda i liknande situationer");
        TranslationService.translations.set("dops-surgery.evaluation.technique.f", "Inte observerat");

        TranslationService.translations.set("dops-surgery.evaluation.sterility", "6. Arbetar sterilt (om det är aktuellt)");
        TranslationService.translations.set("dops-surgery.evaluation.sterility.a", "ST-läkaren observerar annan läkare som utför ingreppet");
        TranslationService.translations.set("dops-surgery.evaluation.sterility.b", "ST-läkaren utför ingreppet med proaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.sterility.c", "ST-läkaren utför ingreppet med reaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.sterility.d", "ST-läkaren utför ingreppet självständigt");
        TranslationService.translations.set("dops-surgery.evaluation.sterility.e", "ST-läkaren utför ingreppet självständigt och har uppvisat kompetens i att själv handleda i liknande situationer");
        TranslationService.translations.set("dops-surgery.evaluation.sterility.f", "Inte observerat");

        TranslationService.translations.set("dops-surgery.evaluation.help", "7. Ber om hjälp på ett adekvat sätt");
        TranslationService.translations.set("dops-surgery.evaluation.help.a", "ST-läkaren observerar annan läkare som utför ingreppet");
        TranslationService.translations.set("dops-surgery.evaluation.help.b", "ST-läkaren utför ingreppet med proaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.help.c", "ST-läkaren utför ingreppet med reaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.help.d", "ST-läkaren utför ingreppet självständigt");
        TranslationService.translations.set("dops-surgery.evaluation.help.e", "ST-läkaren utför ingreppet självständigt och har uppvisat kompetens i att själv handleda i liknande situationer");
        TranslationService.translations.set("dops-surgery.evaluation.help.f", "Inte observerat");

        TranslationService.translations.set("dops-surgery.evaluation.termination", "8. Avslutar ingreppet på ett adekvat sätt");
        TranslationService.translations.set("dops-surgery.evaluation.termination.a", "ST-läkaren observerar annan läkare som utför ingreppet");
        TranslationService.translations.set("dops-surgery.evaluation.termination.b", "ST-läkaren utför ingreppet med proaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.termination.c", "ST-läkaren utför ingreppet med reaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.termination.d", "ST-läkaren utför ingreppet självständigt");
        TranslationService.translations.set("dops-surgery.evaluation.termination.e", "ST-läkaren utför ingreppet självständigt och har uppvisat kompetens i att själv handleda i liknande situationer");
        TranslationService.translations.set("dops-surgery.evaluation.termination.f", "Inte observerat");

        TranslationService.translations.set("dops-surgery.evaluation.communication", "9. Kommunikationsförmåga");
        TranslationService.translations.set("dops-surgery.evaluation.communication.a", "ST-läkaren observerar annan läkare som utför ingreppet");
        TranslationService.translations.set("dops-surgery.evaluation.communication.b", "ST-läkaren utför ingreppet med proaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.communication.c", "ST-läkaren utför ingreppet med reaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.communication.d", "ST-läkaren utför ingreppet självständigt");
        TranslationService.translations.set("dops-surgery.evaluation.communication.e", "ST-läkaren utför ingreppet självständigt och har uppvisat kompetens i att själv handleda i liknande situationer");
        TranslationService.translations.set("dops-surgery.evaluation.communication.f", "Inte observerat");

        TranslationService.translations.set("dops-surgery.evaluation.summary", "10. Sammanfattande bedömning av förmågan att genomföra ingreppet");
        TranslationService.translations.set("dops-surgery.evaluation.summary.a", "ST-läkaren observerar annan läkare som utför ingreppet");
        TranslationService.translations.set("dops-surgery.evaluation.summary.b", "ST-läkaren utför ingreppet med proaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.summary.c", "ST-läkaren utför ingreppet med reaktiv handledning");
        TranslationService.translations.set("dops-surgery.evaluation.summary.d", "ST-läkaren utför ingreppet självständigt");
        TranslationService.translations.set("dops-surgery.evaluation.summary.e", "ST-läkaren utför ingreppet självständigt och har uppvisat kompetens i att själv handleda i liknande situationer");
        TranslationService.translations.set("dops-surgery.evaluation.summary.f", "Inte observerat");

        TranslationService.translations.set("dops-surgery.feedback.good", "Exempel på vad som gick särskilt bra");
        TranslationService.translations.set("dops-surgery.feedback.improvement", "Konkreta exempel som belyser vad som skulle kunna förbättras och förslag till hur förbättring kan uppnås");
        TranslationService.translations.set("dops-surgery.comment", "Kommentar");

        //error messages

        TranslationService.translations.set("countersign.overridden", "Kontrasigneringen är inte längre aktuell - studenten har skickat till annan handledare");
        TranslationService.translations.set("countersign.expired", "Länken är för gammal");
        TranslationService.translations.set("countersign.already.signed", "Formuläret är redan signerat");

        TranslationService.translations.set("User has been locked out", "Kontot har blivit låst, byt lösenord för att låsa upp.");
        TranslationService.translations.set("Error: Email or Password incorrect", "Felaktigt lösenord eller e-post");


        //error messages relating to password
        TranslationService.translations.set("PASSWORDMINLENGTH", "För kort lösenord");
        TranslationService.translations.set("PASSWORDMINCAPITAL", "För få stora bokstäver i lösenordet");
        TranslationService.translations.set("PASSWORDMINLOWERCASE", "För få små bokstäver i lösenordet");
        TranslationService.translations.set("PASSWORDMINNUMERIC", "För få siffror i lösenordet");

        TranslationService.translations.set("delivery", "BETA Förlossning");
        TranslationService.translations.set("delivery.countersign.student.reflect", "Studenten vill reflektera tillsammans med dig.")

        //temporary, move these to backend translations once that is sorted
        TranslationService.translations.set("Filter", "Filtrera");
        TranslationService.translations.set("Reset search", "Ta bort filtret");
    }

    private static addEnglishDefault() {
        TranslationService.defaultEnglishTranslations.set("Förnamn", "First name");
        TranslationService.defaultEnglishTranslations.set("Efternamn", "Last name");
        TranslationService.defaultEnglishTranslations.set("Lösenord", "Password");
        TranslationService.defaultEnglishTranslations.set("Telefon", "Phone number");
        TranslationService.defaultEnglishTranslations.set("Språk", "Language");
        TranslationService.defaultEnglishTranslations.set("Område", "Role");
        TranslationService.defaultEnglishTranslations.set("mandatory.fields,in.form", "Mandatory fields are marked with an asterisk (*)");
        TranslationService.defaultEnglishTranslations.set("Skapa konto", "Create Account");
        TranslationService.defaultEnglishTranslations.set("Logga in", "Login");
        TranslationService.defaultEnglishTranslations.set("Jag har glömt mitt lösenord", "I have forgotten my password");
        TranslationService.defaultEnglishTranslations.set("Du kommer strax få ett mail med en länk till en registreringssida.", "You will soon get an email with a link to the registration page.");
        TranslationService.defaultEnglishTranslations.set("På grund av spam kan vår leverantör av e-posttjänster inte alltid skicka mail till hotmail-adresser.", "Due to spam our email-provider can't always reach you on a hotmail account.");
        TranslationService.defaultEnglishTranslations.set("Vi förstår att det kan vara ett problem, men det är utom vår kontroll och vi har ännu inte lyckats hitta en lösning.", "We understand this can cause friction, but it is currently out of our hands.");
        TranslationService.defaultEnglishTranslations.set("Har du inte fått en inbjudan om en stund så kontakta oss via e-post", "If you haven't received an email, contact us via email ");
        TranslationService.defaultEnglishTranslations.set("Här är en", "Here is an");
        TranslationService.defaultEnglishTranslations.set("om du vill se hur man gör innan du registrerar dig.", "If you want to know how it works before registering.");
        TranslationService.defaultEnglishTranslations.set("instruktionsfilm", "instructionfilm in swedish");
        TranslationService.defaultEnglishTranslations.set("Registrera", "Sign up");
        TranslationService.defaultEnglishTranslations.set("E-post:", "Email:");
        TranslationService.defaultEnglishTranslations.set("Kul och välkommen till LogEze!", "Welcome to LogEze");
        TranslationService.defaultEnglishTranslations.set("Du får strax ett mail med instruktioner till:", "You will soon receive instructions to:");
        TranslationService.defaultEnglishTranslations.set("placerar ofta mailet bland \"Kampanjer\".", "often places it in \"Forums\" o \"Promotions\".");
        TranslationService.defaultEnglishTranslations.set("kan ibland spärra vårt mail av oklar anledning.", "can sometimes restrict our mail due to unknown reasons");
        TranslationService.defaultEnglishTranslations.set("Har du inte fått en inbjudan om en stund så kontakta oss via e-post", "If you haven't received an invitation after a while contact us via email");
        TranslationService.defaultEnglishTranslations.set("Du har angivit en icke godkänd email-adress", "Email does not fill our requirements");
        TranslationService.defaultEnglishTranslations.set("Återställ ditt lösenord", "Reset password");
        TranslationService.defaultEnglishTranslations.set("Ange din e-postadress", "Submit email associated with account");
        TranslationService.defaultEnglishTranslations.set("Byt lösenord", "Change password");
        TranslationService.defaultEnglishTranslations.set("Inte en mailaddress", "Invalid email");
        TranslationService.defaultEnglishTranslations.set("E-post", "Email");
        TranslationService.defaultEnglishTranslations.set("Lösenord", "Password");
        TranslationService.defaultEnglishTranslations.set("Anestesi", "Anaesthesia");
        TranslationService.defaultEnglishTranslations.set("Arbetsplats", "Workplace");
        TranslationService.defaultEnglishTranslations.set("Fyll i dina uppgifter", "Fill in your details");
        TranslationService.defaultEnglishTranslations.set("HSA-ID används ibland för att hitta kollegor.", "HSA-ID is sometimes used to identify colleagues.");

    }

    private static setupDomainToLangDefault() {
        TranslationService.domainToLang.set("com", TranslationLanguage.english);
        TranslationService.domainToLang.set("se", TranslationLanguage.svenska);
        TranslationService.domainToLang.set("fi", TranslationLanguage.finska);
    }
}

export default TranslationService;
