import React from "react";
import {Binder, BinderId, Book, BookId, KnarkbokType} from "./models/Models";
import KnarkbokModal from "./KnarkbokModal";
import "./KnarkbokModal.css"
import "./ToggleSwitch.css"
import "./Knarkbokuserpage.css"
import {KnarkbokService} from "./Knarkbokservice";
import {BackendContext} from "../../infra/BackendContext";
import {Organisation} from "../model/Organisation";
import FullScreenModal from "./FullScreenModal";
import TranslationService from "../../infra/TranslationService";
import SearchBar from "./SearchBar";

interface props {
    KBService: KnarkbokService
    organisation: Organisation
    drugUser: boolean
    drugLord: boolean
    drugBook: string
}

interface state {
    books: Book[],
    binders: Binder[] | undefined;
    administer: boolean,
    move: boolean,
    discard: boolean,
    check: boolean,
    currentId: BookId | null,
    currentBinder: Binder | undefined,
    isDrugLord: boolean
    acceptDelivery: boolean
    showDeliveryPopup: boolean,
    requireWitness: boolean,
    canMoveDrugs: boolean,
    partialAdminister: boolean,
    searchArgument: string
    canReturnDrugFromPatient?: boolean
}

class Knarkbokuserpage extends React.Component<props, state> {
    KBService: KnarkbokService;
    static contextType = BackendContext;

    constructor(props: any) {
        super(props);
        this.state = {
            books: [],
            check: false,
            administer: false,
            move: false,
            discard: false,
            currentId: null,
            currentBinder: undefined,
            binders: undefined,
            isDrugLord: false,
            acceptDelivery: false,
            showDeliveryPopup: false,
            requireWitness: false,
            canMoveDrugs: false,
            partialAdminister: false,
            searchArgument: "",
            canReturnDrugFromPatient: false
        }
        //TODO move all pointers from the local one to props version
        this.KBService = this.props.KBService;
    }

    componentDidMount() {
        this.getBinder();
    }

    componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<state>, snapshot?: any) {
        // Fetch the drug book even if we haven't logged out.
        // The user may have just scanned a new qr code a second after the first one.
        if (prevProps.drugBook !== this.props.drugBook) {
            this.getBinder();
        }
    }

    render() {
        const state = this.state;
        let dispBook = <div/>;
        let actionModal = <div/>;
        let deliveryModal = <div/>;
        const organisation: Organisation = this.props.organisation;
        const {canMoveDrugs} = this.state;

        if (state.discard || state.check || state.administer || state.move || state.partialAdminister) {
            const type = this.setType(state);
            const currentBook = this.state.books.find((b) => b.id.id === this.state.currentId?.id);
            const allowedToCreatePartials = currentBook?.drug?.allowPartials || false;

            actionModal =
                <KnarkbokModal isDruglord={this.state.isDrugLord}
                               KBService={this.KBService}
                               canReturnDrugFromPatient={this.state.canReturnDrugFromPatient}
                               flipModal={this.turnOffModal}
                               type={type}
                               bookId={this.state.currentId}
                               flipToPartial={() => this.flipToPartial()}
                               flipToAdminister={() => this.flipToAdminister()}
                               allowedToCreatePartials={allowedToCreatePartials}
                               organisation={organisation}
                               partials={currentBook?.partials}
                />;
        }

        if (state.showDeliveryPopup) {
            deliveryModal = <FullScreenModal flipModal={this.turnOffDeliveryModal}
                                             binder={this.state.currentBinder}
                                             update={this.getBinder}
                                             requireWitness={this.state.requireWitness}
                                             organisation={organisation}/>;
        }

        let druglordSwitch = <div/>
        if (this.props.drugLord) {
            druglordSwitch =
                <div className={"pb-3"}><span
                    className={"span-text"}>Jag loggar som narkotikaansvarig</span><label
                    className="switch">
                    <input type="checkbox"
                           defaultChecked={false}
                           onChange={() => this.handleDruglord()}
                    />
                    <span className="slider round"/>
                </label></div>;
        }

        const deliveryButton = this.state.acceptDelivery ? <div className={"kb-position-relative"}>
            <div className={"col m-0 p-0"}>
                <button onClick={() => this.flipShowDelivery()}
                        className={"btn btn-tage-default btn-tage-green"}>
                    Påfyllning från apoteket
                </button>
            </div>
        </div> : "";

        const binderHeader = <div className={"row m-0 p-0"}>
            <div className={"col-4 m-0 p-0"}>
                <div aria-label={'drug-book-header'}
                     className={"pb-1"}>
                    <h4>{this.state.currentBinder?.locationName}</h4>
                </div>
            </div>
            <div className={"col-8 m-0 p-0 text-right"}>
                {deliveryButton}
            </div>
        </div>;

        const cancelButton = <div className={"col m-0 p-0"}>
            <div className={"row m-0 pl-3 pb-2 pt-2"}>
                <button aria-label={'cancel button'}
                        onClick={() => this.routeHome()}
                        className="btn btn-tage-default">
                    Avsluta
                </button>
            </div>
        </div>;


        let searchBar = <SearchBar resetSearch={() => this.resetSearch()}
                                   searchArgument={this.state.searchArgument}
                                   handleSearch={(e) => this.handleSearch(e)}/>

        const books: Book[] = this.state.books;
        if (books.length > 0) {
            const buttonClass = "btn btn-tage-somethingelse";
            dispBook = <div className={"userpage-box container p-0"}>
                {druglordSwitch}
                {searchBar}
                {
                    books.map((book: Book) => {
                        return this.createBookCard(book, buttonClass, canMoveDrugs);
                    })
                }
            </div>;
        }

        return <div aria-label={'drug book'}
                    className={"container pt-4"}>
            <div className={"justify-content-center"}>
                {binderHeader}
                {dispBook}
                {cancelButton}
            </div>
            {actionModal}
            {deliveryModal}
        </div>;
    }

    private createBookCard(book: Book, buttonClass: string, canMoveDrugs: boolean) {
        const {tr} = this;
        let buttonClassRecentlyCheck: string = "btn btn-tage-somethingelse";
        if (book.recentlyChecked) {
            buttonClassRecentlyCheck = 'btn btn-tage-recently-checked'
        }


        let headerText: string[] = [];
        headerText.push(book.drug?.name + " - Nuvarande saldo: " + book.inventory)

        if (book.partials && book.partials.length) {
            book.partials.forEach((book) => {
                headerText.push(`Öppnad: ${book.name}`);
                }
            )
        }

        let administer = () => this.flipAdminister(book.id);
        if (this.containsPartialDrugs(book)) {
            administer = () => this.flipPartialAdminister(book.id);
        }

        let sentEmailWarning = <div/>
        const warningString = tr("Sent email warning");
        const warningArray = [];
        let index = 1;
        for (let c of warningString) {
            const style = {"--i-knarkbok": index++, "padding-left": "1px"} as React.CSSProperties;
            warningArray.push(<h6 style={style}>{c === " " ? '\xa0' : c}</h6>)
        }

        if (book.sentEmailWarning) {
            sentEmailWarning = <h6 aria-label={"sent-email-warning"} className={"sent-email-warning"}>
                <div className="wave">
                    {warningArray}
                </div>
            </h6>
        }


        if (this.isSearchedFor(book.drug?.name)) {
            let moveName = this.state.canReturnDrugFromPatient ? tr("Returnera") : tr("Flytta")
            let canMoveDrugsReEvaluated;
            if (this.state.canReturnDrugFromPatient) {
                canMoveDrugsReEvaluated = false;
            } else {
                canMoveDrugsReEvaluated = !canMoveDrugs;
            }

            return <div key={book.id.id} className={"row m-0 p-0 userpage-drug-box "}>
                <div className={"col m-0 p-0"}>
                    {
                        headerText.map((header, i) => {
                            return <div key={"headerDrug" + i}
                                        aria-label={header}
                                        className={"row header-row m-0 pl-3"}>
                                {i > 0 ? <p className={"p-0 m-0 sub-drug-header"}>{header}</p> :
                                    <div className={"row m-0 p-0"}><h6>{header}</h6>{sentEmailWarning}</div>}
                            </div>
                        })
                    }
                    <div className={"row m-0 pt-3"}>

                        <div className={"col-3 pb-3 pl-1 pr-1 text-center"}>
                            <button className={buttonClass}
                                    disabled={this.state.isDrugLord}
                                    aria-label={"administer"}
                                    onClick={administer}>
                                Ge
                            </button>
                        </div>

                        {<div className={"col-3 pb-3 pl-1 pr-1 text-center"}>
                            <button className={buttonClass}
                                    disabled={this.state.isDrugLord || canMoveDrugsReEvaluated}
                                    onClick={() => this.flipMove(book.id)}>
                                {moveName}
                            </button>
                        </div>}

                        <div className={"col-3 pb-3 pl-1 pr-1 text-center"}>
                            <button className={buttonClass}
                                    disabled={this.state.isDrugLord}
                                    onClick={() => this.flipDiscard(book.id)}>
                                Kassera
                            </button>
                        </div>

                        <div className={"col-3 pb-3 pl-1 pr-1 text-center"}>
                            <button className={buttonClassRecentlyCheck}
                                    onClick={() => this.flipCheck(book.id)}>
                                Kontroll
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        } else {
            return <div></div>
        }
    }

    private containsPartialDrugs(book: Book) {
        return !!(book.partials && book.partials.length > 0);

    }

    private setType(state: Readonly<state>): KnarkbokType {
        if (state.discard) {
            return KnarkbokType.discard;
        }
        if (state.check) {
            return KnarkbokType.check;
        }
        if (state.administer) {
            return KnarkbokType.administer;
        }
        if (state.move) {
            return KnarkbokType.move;
        }
        if (state.partialAdminister) {
            return KnarkbokType.partial_administer
        }

        return KnarkbokType.check;
    }

    getBinder = () => {
        const drugBook: string = this.props.drugBook;

        const booksAndBinder: Promise<{
            books: Book[];
            binder: Binder;
            acceptDelivery: boolean,
            requireWitness: boolean,
            canMoveDrugs: boolean
        }> = this.KBService.getBooksFromPathParam(drugBook);

        booksAndBinder.then((value: {
            books: Book[];
            binder: Binder,
            canMoveDrugs: boolean,
            canReturnDrugFromPatient?: boolean
            requireWitness?: boolean,
        }) => {
            if (value) {
                this.setState({
                    books: value.books,
                    currentBinder: value.binder,
                    acceptDelivery: !!value.binder.acceptDelivery, //should this be in the request?
                    requireWitness: !!value.requireWitness,
                    canMoveDrugs: value.canMoveDrugs,
                    canReturnDrugFromPatient: !!value.canReturnDrugFromPatient
                });
            }
        });
    }

    private flipAdminister(id: BookId) {
        this.setState({administer: true, currentId: id})
    }

    private flipDiscard(id: BookId) {
        this.setState({discard: true, currentId: id})
    }

    private flipCheck(id: BookId) {
        this.setState({check: true, currentId: id})
    }

    private flipMove(id: BookId) {
        this.setState({move: true, currentId: id})
    }

    private flipPartialAdminister(id: BookId) {
        this.setState({partialAdminister: true, currentId: id})
    }

    turnOffModal = async (bookId?: BookId, sentEmailWarning?: boolean) => {
        this.setState({
            check: false,
            administer: false,
            move: false,
            discard: false,
            partialAdminister: false,
            currentId: null
        });


        if (this.state.currentBinder) {
            let binderId: BinderId = this.state.currentBinder.id;
            const books: Book[] = await this.KBService.getBooksPerBinderId(binderId);

            /*
            //TODO remove
            let asdf=books.find((b) => b.drug?.name === "Crocodile")
            if(asdf){
                asdf.allowedToCreatePartials = true;
            }
            //TODO remove
             */

            if (sentEmailWarning) {
                books.forEach((book: Book) => {
                    if (book.id.id === bookId?.id) {
                        book.sentEmailWarning = true;
                    }
                })
            }

            this.setState({books})
        }
    }

    turnOffDeliveryModal = () => {
        this.setState({showDeliveryPopup: false});
    }

    private handleDruglord() {
        this.setState({isDrugLord: !this.state.isDrugLord})
    }

    private flipShowDelivery() {
        this.setState({showDeliveryPopup: !this.state.showDeliveryPopup});
    }

    private routeHome() {
        window.location.hash = '#/';
    }

    private handleSearch(e: React.ChangeEvent<HTMLInputElement>) {
        this.setState({searchArgument: e.currentTarget.value})
    }

    private isSearchedFor(name: string | undefined) {
        const {searchArgument} = this.state;
        if (!searchArgument) {
            return true;
        }

        if (!name) {
            return false;
        }

        const loweredSearchArg = searchArgument.toLowerCase();
        const loweredName = name.toLowerCase();

        return loweredName.includes(loweredSearchArg);
    }

    private resetSearch() {
        this.setState({searchArgument: ""});
    }

    private tr(str: string): string {
        return TranslationService.translation(str)
    }

    private flipToPartial(): void {
        this.setState({
            administer: false,
            partialAdminister: true
        })
    }

    private flipToAdminister(): void {
        this.setState({
            administer: true,
            partialAdminister: false
        })
    }
}

export default Knarkbokuserpage;
