import React from 'react';
import {User} from "../model/User";
import {Organisation, OrganisationLabel} from "../model/Organisation";
import {Organisation as Org} from "../user/Organisation";
import "./OrganisationSelector.css";
import TranslationService from "../../infra/TranslationService";
import Loading2 from "../../infra/Loading2";
import {BackendContext, BackendInterface, Result} from "../../infra/BackendContext";
import {NavBar} from "../model/NavBar";
import {
    isPlaceholderOrganisation,
    selectDefaultOrganisation,
    selectOrganisation,
    shouldSeeOrganisationSelector
} from "./SelectOrganisation";
import CreatableSelect from "react-select/creatable";
import {OptionType} from "../model/SelectOption";
import {components, SingleValue} from "react-select";
import {EventBackendService} from "../register/v2/EventBackendService";

interface props {
    user: User,
    updateNavBar: (navBar: NavBar) => void,
    setCurrentOrganisation: (organisation: Organisation | undefined) => void,
    updateUser: (user: User) => void,
    testing: boolean
}

interface state {
    translationsReady: boolean,
    fetchingOrganisation: boolean,
    organisationLabels: OrganisationLabel | undefined,
    selectedOrganisations: Org[],
    availableOrganisations: Org[],
    allOrganisations: Organisation[],
    unspecifiedIndex: number,
    user: User
}

interface Organisations {
    availableOrganisations: Org[];
}

class OrganisationSelector extends React.Component<props, state> {
    amIMounted: boolean = false;
    static contextType: React.Context<BackendInterface> = BackendContext;

    constructor(props: props) {
        super(props);

        this.fetchingOrganisation = this.fetchingOrganisation.bind(this);
        this.onChange = this.onChange.bind(this);
        const unspecifiedIndex = this.props.user.organisations?.findIndex(org =>
            isPlaceholderOrganisation(org)
        );


        this.state = {
            translationsReady: this.props.testing,
            fetchingOrganisation: false,
            organisationLabels: undefined,
            selectedOrganisations: [],
            availableOrganisations: [],
            allOrganisations: this.props.user.organisations,
            unspecifiedIndex: unspecifiedIndex,
            user: this.props.user,
        }
    }

    render(): React.JSX.Element {
        const translationsReady: boolean = this.state.translationsReady;
        const fetchingNavBar: boolean = this.state.fetchingOrganisation;
        if ((!translationsReady || fetchingNavBar)) {
            if (this.props.testing) {
                return <div aria-label={"loading balls org-selector"} className={"container justify-content-center"}>
                    Loading balls
                </div>;
            } else {
                return <div className={"container justify-content-center"}>
                    <canvas className={"loading-canvas"}>
                        <p>Loading balls</p>
                    </canvas>
                    <Loading2/>
                </div>;
            }
        }

        const organisations: Organisation[] = this.state.allOrganisations;
        return this.renderOrganisations(organisations);
    }

    async componentDidMount(): Promise<void> {
        this.amIMounted = true;
        if (!this.props.testing) {
            await TranslationService.init();
            this.setState({translationsReady: true});
        }

        await this.letOneOrgUsersInOrPullOrgLabels();

    }

    private async getOrganisationOptions() {
        const path: string = "/api/v1/user-settings/organisations"
        const url: string = EventBackendService.getUrl2(path);
        const response: any = await this.context.get(url, {
            success: "",
            failure: ""
        }) as Organisations;

        if (response !== undefined && response.data !== undefined) {
            this.setState({
                availableOrganisations: response.data.availableOrganisations,
            });
        }
    }

    componentWillUnmount() {
        this.amIMounted = false;
    }

    private renderOrganisations(organisations: Organisation[]): React.JSX.Element {
        const user: User = this.state.user;
        const updateNavBar = this.props.updateNavBar;
        const setCurrentOrganisation = this.props.setCurrentOrganisation;
        const post = this.context.post;
        const fetchingOrganisation = this.fetchingOrganisation;
        const updateUser = this.props.updateUser;
        const whereDoYouWorkTodayLabel = TranslationService.translation('where do you work today');
        const pleaseSelectAWorkingPlaceToLogInTo = TranslationService.translation('please select a working place to log in to');

        return <div className="dashboard"
                    aria-label={"organisation-selector-page"}>
            <div className="header">
                <h1>
                    {whereDoYouWorkTodayLabel}
                </h1>
                <p>
                    {pleaseSelectAWorkingPlaceToLogInTo}
                </p>
            </div>

            {organisations !== undefined && organisations.length > 0 ? organisations.map((org) => {
                let onClick = (e: any) => {
                    e.stopPropagation();
                    OrganisationSelector.handleOnClick(e, org, user, updateNavBar, setCurrentOrganisation, post, fetchingOrganisation, updateUser);
                };

                let newWorkplaceSelectorDescription: React.JSX.Element = <div/>;
                let newWorkplaceSelector: React.JSX.Element = <div/>;
                if (isPlaceholderOrganisation(org)) {
                    /*
                    const pleaseSelectAWorkplace: string = TranslationService.translation('please select a workplace');
                    onClick = (e: any) => {
                        alert(pleaseSelectAWorkplace)
                    };
                     */

                    const searchForYourWorkplace: string = TranslationService.translation('search for your work place');
                    newWorkplaceSelectorDescription = <div>
                        {searchForYourWorkplace}
                    </div>
                    newWorkplaceSelector = this.selectWorkplace();
                }
                let deleteButton = null;
                if (org.canBeDeleted) {
                    deleteButton = <button
                        className="delete-btn"
                        onClick={(e) => {
                            e.stopPropagation();
                            this.deleteOrganisation(org.organisationId);
                        }}
                        aria-label={`Delete ${org.organisationName}`}
                    >&times;
                    </button>;
                }

                const organisationName: string = TranslationService.translation(org.organisationName);

                return <div
                    key={org.organisationId}
                    aria-label={org.organisationName}
                    className="parent-card"
                    tabIndex={0}
                    onClick={onClick}
                >
                    {deleteButton}
                    <h2>{organisationName}</h2>
                    {newWorkplaceSelectorDescription}
                    {newWorkplaceSelector}

                    {org.children.map((child) => this.renderChild(child))}
                </div>
            }) : <div/>}
        </div>
    }

    private selectWorkplace(): React.JSX.Element {
        let options = this.transform(this.state.availableOrganisations);
        const customControl = (props: any) => (
            <div
                onMouseDown={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                }}
                onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                }}
            >
                <components.Control {...props}>{props.children}</components.Control>
            </div>
        );

        const customMenu = (props: any) => (
            <div
                onMouseDown={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                }}
                onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                }}
                onFocus={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                }}
            >
                <components.Menu {...props}>{props.children}</components.Menu>
            </div>
        );

        const customOption = (props: any) => (
            <div id={"workplace-options"}
                 onMouseDown={(event) => {
                     event.stopPropagation();
                     event.preventDefault();
                 }}
                 onClick={(event) => {
                     event.stopPropagation();
                     event.preventDefault();
                 }}
            >
                <components.Option {...props}>{props.children}</components.Option>
            </div>
        );
        return <div aria-label={"workplace-select"}
                    data-testid="workplace-select"
        >
            <CreatableSelect
                id={"workplace-select"}
                tabIndex={0}
                options={options}
                classNamePrefix="custom-select"
                isMulti={false}
                onChange={this.onChange}
                onFocus={(event) => event.stopPropagation()}
                value={this.getCurrentOrganisations()}
                placeholder={TranslationService.translation("select workplace")}
                menuPortalTarget={document.body}
                menuPosition="fixed"
                menuPlacement="auto"
                components={{
                    Control: customControl,
                    Menu: customMenu,
                    Option: customOption,
                }}
            />
        </div>
    }

    private fetchingOrganisation(fetchingOrganisation: boolean): void {
        if (this.amIMounted) {
            this.setState({
                fetchingOrganisation: fetchingOrganisation
            });
        }
    }

    private getCurrentOrganisations(): OptionType[] {
        return this.transform(this.state.selectedOrganisations);
    }

    private onChange(selectedOption: SingleValue<OptionType>) {
        if (selectedOption) {
            let newOrganisation: Org = {organisationId: selectedOption.value, organisationName: selectedOption.label};
            let url = "/api/v1/organisation-selector/new-organisation";

            const result: Promise<Result> = this.context.post(url, JSON.stringify(newOrganisation), {
                failure: ""
            });
            result.then((result: Result) => {
                if (result !== undefined) {
                    let newOrganisation: Organisation = result.data as Organisation;
                    let organisations = [...this.state.allOrganisations];

                    const unspecified = organisations.splice(this.state.unspecifiedIndex, 1)[0];

                    organisations.splice(this.state.unspecifiedIndex, 0, newOrganisation);

                    organisations.splice(this.state.unspecifiedIndex + 1, 0, unspecified);
                    let user = this.state.user;
                    user.organisations = organisations;
                    this.props.updateUser(user);

                    this.setState({
                        allOrganisations: organisations,
                        unspecifiedIndex: organisations.length - 1,
                    });
                }
                this.getOrganisationLabels();
                this.getOrganisationOptions();
            })
        }
    }

    componentDidUpdate(prevProps: Readonly<props>, prevState: Readonly<state>, snapshot?: any) {
        if (prevProps.user !== this.props.user) {
            this.setState({user: this.props.user});
        }
    }


    private transform(organisations: Org[]): OptionType[] {
        let currentOptions: OptionType[] = [];
        for (let organisation of organisations) {
            let currentOption = {label: organisation.organisationName, value: organisation.organisationId};
            currentOptions.push(currentOption);
        }
        return currentOptions;
    }

    private async letOneOrgUsersInOrPullOrgLabels() {
        const currentUser = this.state.user;

        // review this condition when we release selecting organisation to all users.
        if (!shouldSeeOrganisationSelector(currentUser)) {
            const currentOrganisation: Organisation | undefined = OrganisationSelector.getCurrentOrganisation(this.state.user);

            const user: User = this.state.user;
            const updateNavBar = this.props.updateNavBar;
            const setCurrentOrganisation = this.props.setCurrentOrganisation;
            const post = this.context.post;
            const fetchingOrganisation = this.fetchingOrganisation;
            const updateUser = this.props.updateUser;

            selectDefaultOrganisation(currentOrganisation, user, updateNavBar, setCurrentOrganisation, post, fetchingOrganisation, updateUser);
        } else {
            await this.getOrganisationLabels()
            await this.getOrganisationOptions();
        }
    }

    public static getCurrentOrganisation(user: User): Organisation | undefined {
        const organisations: Organisation[] = user.organisations;
        if (organisations?.length === 1) {
            return organisations[0];
        }

        return undefined;
    }

    private renderChild(child: Organisation) {
        const user: User = this.state.user;
        const updateNavBar = this.props.updateNavBar;
        const setCurrentOrganisation = this.props.setCurrentOrganisation;
        const post = this.context.post;
        const fetchingOrganisation = this.fetchingOrganisation;
        const updateUser = this.props.updateUser;
        let deleteButton = null;
        if (child.canBeDeleted) {
            deleteButton = <button
                className="delete-btn"
                onClick={(e) => {
                    e.stopPropagation();
                    this.deleteOrganisation(child.organisationId);
                }}
                aria-label={`Delete ${child.organisationName}`}
            >&times;
            </button>;
        }
        return <div
            key={child.organisationId}
            className="child-card"
            aria-label={child.organisationName}
            tabIndex={0}
            onClick={(e) => {
                e.stopPropagation();
                OrganisationSelector.handleOnClick(e, child, user, updateNavBar, setCurrentOrganisation, post, fetchingOrganisation, updateUser);
            }}
        >
            {deleteButton}

            <h3>{TranslationService.translation(child.organisationName)}</h3>
            {child.children.length > 0 && child.children.map((grandChild) => this.renderChild(grandChild))}
        </div>
    }

    private static handleOnClick(e: React.MouseEvent<HTMLDivElement>, organisation: Organisation, user: User, updateNavBar: (navBar: NavBar) => void, setCurrentOrganisation: (organisation: (Organisation | undefined)) => void, post: any,
                                 fetchingOrganisation: (fetchingOrganisation: boolean) => void,
                                 updateUser: (updateUser: User) => void) {
        const card = e.currentTarget;
        if (
            (e.target as HTMLElement).closest('#workplace-select')

        ) {
            return;
        }

        card.style.transform = 'scale(1.05)';
        setTimeout(() => {
            card.style.transform = 'scale(1)';
            selectOrganisation(organisation.organisationId, user, updateNavBar, setCurrentOrganisation, post, fetchingOrganisation, updateUser);
        }, 500);
    }

    private async getOrganisationLabels() {
        const url: string = '/api/v1/organisation-selector/labels';

        let organisationIds: string[] = [];
        this.getOrganisationIds(this.state.allOrganisations, organisationIds);
        if (organisationIds.length > 0) {
            const result: any = await this.context.post(url, JSON.stringify({organisationIds: organisationIds}), {
                failure: ""
            }) as OrganisationLabel;
            if (result !== undefined && result.data !== undefined) {
                let organisationLabels = result.data;
                this.setState({organisationLabels: organisationLabels});
            }
        }
    }

    private getOrganisationIds(organisations: Organisation[], organisationIds: string[]): void {
        for (const organisation of organisations) {
            organisationIds.push(organisation.organisationId);
            if (organisation.children && organisation.children.length > 0) {
                this.getOrganisationIds(organisation.children, organisationIds);
            }
        }
    }

    private async deleteOrganisation(organisationId: string) {
        let url = `/api/v1/organisation-selector/${organisationId}`;
        const deleteResponse = await this.context.delete(url,
            {
                success: TranslationService.translation("Left the workplace"),
                failure: TranslationService.translation("An error occurred")
            });
        if (deleteResponse.success) {
            let organisations = this.deleteOrganisationById(this.state.allOrganisations, organisationId);
            let user: User = this.props.user;
            user.organisations = organisations;
            this.props.updateUser(user);
            const unspecifiedIndex = organisations.findIndex(org =>
                isPlaceholderOrganisation(org)
            );
            this.setState({allOrganisations: organisations, unspecifiedIndex});
            await this.getOrganisationOptions();
        }
    }

    private deleteOrganisationById(orgs: Organisation[], organisationId: string): Organisation[] {
        return orgs
            .map(org => ({
                ...org,
                children: this.deleteOrganisationById(org.children || [], organisationId)
            }))
            .filter(org => org.organisationId !== organisationId);
    }
}

export default OrganisationSelector;
