import React, {ReactElement} from 'react';
import TranslationService from "../../../infra/TranslationService";
import openEye from "../../../open-eye.png";
import verbotten from "../../../open-eye-verbotten.png";
import "./PasswordInputField.css"

const PASSWORDMINLENGTH = 10;
const PASSWORDMINCAPITAL = 1;
const PASSWORDMINLOWERCASE = 1;
const PASSWORDMINNUMERIC = 1;

interface props {
    password: string;

    update(value: string): any;

    mandatory?: boolean;
    displayErrors: boolean,
    errors: string[],
    placeholder?: string,
    size?: number
}

interface state {
    displayPassword: boolean
}

class PasswordInputField extends React.Component<props, state> {
    constructor(props: Readonly<props>) {
        super(props);
        this.state = {
            displayPassword: false
        }
    }

    render(): ReactElement {
        const errors = this.props.errors.map(e => {
            return <div key={e} className={"row m-0 p-0 flashing-red-warning"}>{TranslationService.translation(e)}</div>
        })
        const type = this.state.displayPassword ? "text" : "password"
        const eye = this.getShowPasswordButton("btn pl-2 pr-2 pt-0 pb-1 mr-2 border-0");

        return <div key={"password-input-field"}>
            <div className={"row m-0 p-0"}>
                <input aria-label={"password field"}
                       data-testid={"password-field"}
                       value={this.props.password}
                       size={this.props.size ? this.props.size : 20}
                       placeholder={this.props.placeholder ? this.props.placeholder : ""}
                       required={this.props.mandatory}
                       type={type}
                       onChange={(e: any) => this.passwordInput(e)}/>
                {eye}

            </div>
            <div className={"container m-0 p-0"}>
                {
                    this.props.displayErrors ? errors : ""
                }
            </div>
        </div>
    }

    private passwordInput = (e: any) => {
        e.preventDefault();
        this.props.update(e);
    }

    getShowPasswordButton = (className: string) => {
        const showPasswordButtonText = "Visa lösenord";
        const imageSrc = this.state.displayPassword ? verbotten : openEye;
        return <button aria-label={"Show password"}
                       className={className}
                       onClick={(e) => this.showPassword(e)}>
            <img height={20} alt={showPasswordButtonText} src={imageSrc}/>
        </button>;
    }

    showPassword = (e: any) => {
        e.preventDefault();
        this.setState({displayPassword: !this.state.displayPassword})
    }

    public static adheresToPasswordRestrictions(value: string): { valid: boolean, errors: string[] } {
        const errors = [];
        let result = true;

        if (!value) {
            result = false;
            errors.push("PASSWORDMINLENGTH")
        }

        if (value && value.length < PASSWORDMINLENGTH) {
            result = false;
            errors.push("PASSWORDMINLENGTH")
        }

        let numericalCount = 0;
        let capitalCount = 0;
        let lowercaseCount = 0;
        for (const letter of value) {
            if (!isNaN(+letter)) {
                numericalCount = numericalCount + 1;
            }

            if (isNaN(+letter) && (letter === letter.toUpperCase())) {
                capitalCount = capitalCount + 1
            }

            if (isNaN(+letter) && (letter === letter.toLowerCase())) {
                lowercaseCount = lowercaseCount + 1
            }
        }
        if (numericalCount < PASSWORDMINNUMERIC) {
            result = false;
            errors.push("PASSWORDMINNUMERIC")
        }

        if (capitalCount < PASSWORDMINCAPITAL) {
            result = false;
            errors.push("PASSWORDMINCAPITAL")
        }

        if (lowercaseCount < PASSWORDMINLOWERCASE) {
            result = false;
            errors.push("PASSWORDMINLOWERCASE")
        }

        return {valid: result, errors: errors};
    }
}

export default PasswordInputField;
