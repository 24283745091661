import React, {ChangeEvent} from "react";
import {unsecuredPost} from "../../infra/BackendService";
import {setSecurityToken} from "../../infra/SecurityToken";
import openEye from "../../open-eye.png";
import {Link} from "react-router-dom";
import "./Login.css";
import frontendHash from '../../meta.json';
import {UserData} from "../model/UserData";
import TranslationService from "../../infra/TranslationService";

interface props {
    loggedIn: () => void
    setUserData: (userData: UserData) => void
    setLoading: (state: boolean) => void
    domain: string
}

interface state {
    email: string
    password: string
    showErrorMessage: boolean
    showPassword: boolean,
    errorMessage: string
}

export interface LoginRequest {
    email: string,
    password: string
}

class Login extends React.Component<props, state> {
    emailPlaceholder: string = "E-post";
    passwordPlaceholder: string = "Lösenord";

    constructor(props: props) {
        super(props);
        this.state = {
            email: "",
            password: "",
            showErrorMessage: false,
            showPassword: false,
            errorMessage: ""
        }
    }

    getEmailInput(size = 20) {
        const email: string = this.state.email;

        return <input type={"text"}
                      name={"email"}
                      id={"username"}
                      size={size}
                      placeholder={TranslationService.translatePerDomain(this.emailPlaceholder, this.props.domain)}
                      value={email}
                      onKeyDown={event => this.onKeyDown(event)}
                      onChange={event => this.onChangeEmail(event)}
        />;
    }

    getPasswordInput(size = 20, showPassword: boolean) {
        const password: string = this.state.password;
        let passwordInputType = "password";
        if (this.state.showPassword) {
            passwordInputType = "text";
        }

        const eye = this.getShowPasswordButton("floating-eye btn pl-2 pr-2 pt-0 pb-1 border-0");
        const passwordInput = <input type={passwordInputType}
                                     name={"password"}
                                     id={"password"}
                                     size={size}
                                     placeholder={TranslationService.translatePerDomain(this.passwordPlaceholder, this.props.domain)}
                                     value={password}
                                     onKeyDown={event => this.onKeyDown(event)}
                                     onChange={event => this.onChangePassword(event)}/>

        if (showPassword) {
            return <div>
                {passwordInput}
                {eye}
            </div>;
        }

        return passwordInput
    }

    getLoginButton(className = "btn loginButton") {
        const loginButtonText = "Logga in";
        return <button className={className}
                       id={"submitButton"}
                       onClick={() => this.login()}>
            {TranslationService.translatePerDomain(loginButtonText, this.props.domain)}
        </button>;
    }

    onChangeEmail(event: ChangeEvent<HTMLInputElement>) {
        const email = event.currentTarget.value;
        this.setState({email: email});
    }

    onChangePassword(event: ChangeEvent<HTMLInputElement>) {
        const password = event.currentTarget.value;
        this.setState({password: password});
    }

    getShowPasswordButton(className: string) {
        const showPasswordButtonText = "Visa lösenord";
        return <button aria-label={"Show password"}
                       className={className}
                       onClick={() => this.showPassword()}>
            <img height={20} alt={showPasswordButtonText} src={openEye}/>
        </button>;
    }

    getPasswordRecoveryLink(className: string) {
        const forgotPasswordText = "Jag har glömt mitt lösenord";
        return <Link aria-label={"Password recovery"}
                     className={className}
                     id={"recovery-link-id"}
                     to={"/recovery/new"}>
            {TranslationService.translatePerDomain(forgotPasswordText, this.props.domain)}
        </Link>;
    }

    getErrorMessage() {
        const {errorMessage, showErrorMessage} = this.state;

        if (showErrorMessage) {
            return <div aria-label={errorMessage}
                        className={"col text-center errorMsgBox"}>{TranslationService.translation(errorMessage)}
            </div>;
        } else {
            return <div/>;
        }
    }

    async login() {
        console.log("login")
        this.startLoading();

        const LOGINAPI = "/login";
        const email = this.state.email;
        const password = this.state.password;
        const loginRequest: {
            email: string,
            password: string,
            frontendVersion: string
        } = {
            email,
            password,
            frontendVersion: frontendHash.version
        }

        try {
            const loginResult: any = await unsecuredPost(loginRequest, LOGINAPI);

            const securityToken: string = loginResult.securityToken;
            setSecurityToken(securityToken);

            sessionStorage.setItem("user", loginResult.user.userName);
            sessionStorage.setItem("LGDIN", "true");
            sessionStorage.setItem("userData", JSON.stringify(loginResult));
            this.props.setUserData(loginResult);

            this.stopLoading();
            this.props.loggedIn();
        } catch (e) {

            if (e.message === "Locked") {

                this.setState({errorMessage: "User has been locked out"})

            } else {
                this.setState({errorMessage: "Error: Email or Password incorrect"})
            }

            this.stopLoading();
            this.setState({showErrorMessage: true});
        }
    }

    startLoading() {
        this.props.setLoading(true);
    }

    stopLoading() {
        const path = window.location.hash;
        if (!path.includes("/fjn/") && !path.includes("countersign")) {
            window.location.hash = '#/';
        }
        this.setState({showErrorMessage: false});
        this.props.setLoading(false);
    }

    showPassword() {
        const showPassword: boolean = !this.state.showPassword;
        this.setState({showPassword: showPassword})
    }

    private onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            this.login();
        }
    }
}

export default Login;
